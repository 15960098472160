import { product } from "../data/products.js";

const handleProductSelect = (selectedSku: string): [] => {
  return product.filter((item) => {
    if (item.sku === selectedSku) {
      return item;
    }
  });
};

export default handleProductSelect;
