import { product } from "../../data/products.js";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [...product],
  filterProducts: [...product],
  selectedCountry: "usa", // Add a default country
  selectedPrice: 0,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setPrice(state, action) {
      state.selectedPrice = action.payload;
    },
    setFilterProducts(state, action) {
      const { selectedCategories } = action.payload;

      const newProducts = JSON.parse(
        localStorage.getItem("countrySelectedProducts")
      );

      if (selectedCategories !== "all") {
        const filterItems = newProducts.filter(
          (item) => item.imgFoundIn == selectedCategories
        );
        console.log(newProducts);
        state.filterProducts = filterItems;
        return;
      }
    },
    setProducts(state, action) {
      const newProducts = product.filter((item) =>
        item.shipsTo.includes(action.payload)
      );

      localStorage.setItem(
        "countrySelectedProducts",
        JSON.stringify(newProducts)
      );

      state.products = [...newProducts];
      state.filterProducts = [...newProducts];
    },
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
    },
  },
});

export const { setFilterProducts, setProducts, setSelectedCountry, setPrice } =
  filterSlice.actions;
export default filterSlice.reducer;
