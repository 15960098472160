import React from "react";
import Slider from "react-slick";
import ProductCard from "../informationComponents/ProductCard.tsx";
import LeftArrow from "../../components/buttonsComponents/PrevButton.tsx";
import RightArrow from "../../components/buttonsComponents/NextButton.tsx";
import { product } from "../../data/products.js";
import { Link } from "react-router-dom";

interface Props {
  styles: Record<string, string>;
  imgLink?: string;
  previewImg?: string;
}

const ItemIamgeCarousel = ({ styles, imgLink, previewImg }: Props) => {
  const itemImages = product.slice(1, 50);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Shuffle the product array
  const shuffledProducts = [...itemImages].sort(() => Math.random() - 0.5);

  return (
    <>
      <Slider {...settings}>
        {shuffledProducts.map((img) => {
          return (
            <Link to={`/item-page/${img.sku || model}`} key={img.sku}>
              <img src={img.imgLink} alt={img.description || img.Description} />
            </Link>
          );
        })}
      </Slider>
    </>
  );
};

export { ItemIamgeCarousel };
