import React from "react";
import styles from "../styles/partials/homePage/homePage.module.css";
import MainSection from "../components/homeComponents/MainSection.tsx";
import SellingSection, {
  OccasionSection,
} from "../components/homeComponents/SellingSection.tsx";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import ProductSection from "../components/productPageComponents/ProductSection.tsx";

export default function HomePage() {
  return (
    <div className={styles.home_page_container}>
      <MainSection styles={styles} />
      <OccasionSection styles={styles} />

      {/* selling section with send love banner. */}
    </div>
  );
}
