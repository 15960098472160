import React, { useEffect, useState } from "react";
import styles from "../styles/partials/partnerLoginPage/PartnerLoginPage.module.css";
import { ReactComponent as LogoWhite } from "../images/logo-white-text.svg";
import PageContent from "../components/partnerLoginComponents/PageContent.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import vendorAuth from "../functions/vendorFunctions/vendorAuth.js";
import { useNavigate } from "react-router-dom";
import fetchAllUsers from "../functions/userAuthFunctons/fetchAllUsers.ts";
import axios from "axios";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com", // Proxy server URL
});
const PartnerLoginPage = () => {
  const { isAuthenticated, user } = useAuth0();
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const navigate = useNavigate();

  return (
    <section className={styles.main}>
      <LogoWhite className={styles.logo_white} />
      <PageContent styles={styles} />
    </section>
  );
};

// This function is hypothetical and should be replaced with the actual database check

export default PartnerLoginPage;
