import React from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { ReactComponent as Logo } from "../../../images/logo-white-text.svg";

type Props = {
  handleAdminComponent: (component: string) => void;
  handleLogout: () => void;
};
export default function sideBard({
  handleAdminComponent,
  handleLogout,
}: Props) {
  return (
    <Box
      elevation={3}
      sx={{
        padding: 3,
        width: "300px",
        height: "80vh",
        display: "flex",
        backgroundColor: "#fcfaf7",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Logo />
      <Typography variant="h4" gutterBottom>
        Seller Dashboard
      </Typography>
      {/* Add the rest of your dashboard content here */}
      <Grid container spacing={2}>
        {/* Example: Display some dashboard information */}
        <Grid item xs={12}>
          <Typography variant="body1">
            Welcome to your Seller Dashboard!
          </Typography>
        </Grid>
        {/* Add more grid items for additional dashboard content */}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdminComponent("home")}
        sx={{ marginTop: 2 }}
      >
        Home
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdminComponent("add product")}
        sx={{ marginTop: 2 }}
      >
        Add Products
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAdminComponent("my products")}
        sx={{ marginTop: 2 }}
      >
        My Products
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogout}
        sx={{ marginTop: 2 }}
      >
        Logout
      </Button>
    </Box>
  );
}
