import { createSlice } from "@reduxjs/toolkit";

interface CategoryState {
  selecetedCategory: string;
}

const initialState: CategoryState = {
  selectedCategory: "all",
};

const categorySlice = createSlice({
  name: "category", //name of the slice
  initialState, //initial state of the slice
  reducers: {
    setCategory(state, action) {
      state.selectedCategory = action.payload;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
});

export const { setCategory } = categorySlice.actions;

export default categorySlice.reducer;
