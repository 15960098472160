import React from "react";
import styles from "../styles/partials/landingPage/landingPage.module.css";
import EmailInput from "../components/inputs/EmailInput.tsx";
import { ReactComponent as LogoWhite } from "../images/logo-white-text.svg";
import { product } from "../data/products.js";
import ProductCard from "../components/informationComponents/ProductCard.tsx";
import {
  ForHerBannerCarousel,
  MainBannerCarsousel,
} from "../components/informationComponents/Banner.tsx";

export default function LandingPage() {
  const previewProducts = product.slice(200, 252);

  console.log(previewProducts);

  return (
    <div className={styles.landing_page_container}>
      <LogoWhite className={styles.logo_white} />
      <h1 className={styles.landing_header}>
        Our website is currently under construction
      </h1>

      <div className={styles.input_container}>
        <div className={styles.text_container}>
          <h5>
            Get 10% to 100% off on your first purchase when subscribing to our
            newsletter
          </h5>
          <EmailInput styles={styles} placeholder="email" button="SUBSCRIBE" />
          <h1>Gift Baskets, Gift Boxes and MORE</h1>
        </div>
        <div className={styles.image_container}>
          <div className={styles.banner_image}></div>
        </div>
      </div>

      <div className={styles.product_container}>
        {previewProducts.map((product) => {
          return (
            <div className="product_card" key={product.sku}>
              <ProductCard product={product} disabled={true} />
            </div>
          );
        })}
      </div>
      <h1>Delivery within Canada and USA</h1>
    </div>
  );
}
