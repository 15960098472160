// ManageProducts.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuthStore from "../../../features/zustandStore/authStore.js";
import { VenProductCard as ProductCard } from "../../informationComponents/ProductCard.tsx";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import { product } from "../../../data/products.js";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
});

export default function ManageProducts() {
  const [venProductData, setVenProductData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [discountDialogOpen, setDiscountDialogOpen] = useState(false);
  const [selectedProductForDiscount, setSelectedProductForDiscount] =
    useState();
  const [discountFormData, setDiscountFormData] = useState({
    discount: "",
    durationInDays: "",
  });
  const sellerEmail = useAuthStore((state) => state.authedEmail);

  useEffect(() => {
    const getVenProductData = async () => {
      try {
        const response = await baseApi.get(`/get-ven-products`);
        console.log(response.data);
        const filteredProducts = response.data.filter((product) => {
          return product.productOwner === sellerEmail;
        });
        setVenProductData(filteredProducts);
      } catch (error) {
        console.error("Failed to fetch ven products:", error);
      }
    };

    getVenProductData();
  }, [sellerEmail]);

  const handleInputChange = (fieldName, value) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      [fieldName]: value,
    }));
  };

  const handleEditProduct = (productId) => {
    const productToEdit = venProductData.find(
      (product) => product._id === productId
    );
    setSelectedProduct(productToEdit);
    setEditDialogOpen(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await baseApi.put(
        `/edit-product/${selectedProduct._id}`,
        selectedProduct
      );
      console.log(response.data); // Log the response from the server
      setEditDialogOpen(false);
      // Add any additional logic after successful update
    } catch (error) {
      console.error("Failed to update product:", error);
      // Handle the error, display a message, or take appropriate action
    }
  };

  const handleCancelEdit = () => {
    setEditDialogOpen(false);
  };

  const handleDeleteProduct = async (productId) => {
    // Implement the logic to delete the product on the server
    try {
      const response = await baseApi.delete(`/delete-product/${productId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data); // Log the response from the server
      // Add any additional logic after successful deletion
    } catch (error) {
      console.error("Failed to delete product:", error);
      // Handle the error, display a message, or take appropriate action
    }
  };

  const handleManageDiscount = (productId) => {
    setDiscountDialogOpen(true);
    setSelectedProductForDiscount(productId);
  };
  const handleApplyDiscount = async () => {
    try {
      const response = await baseApi.post(
        `/apply-discount/${selectedProductForDiscount}`,
        discountFormData
      );

      console.log(response.data); // Log the response from the server

      // Add any additional logic after successful discount application

      setDiscountDialogOpen(false);
    } catch (error) {
      console.error("Failed to apply discount:", error);
      // Handle the error, display a message, or take appropriate action
    }
  };

  const handleCancelDiscount = () => {
    setDiscountDialogOpen(false);
  };

  const handleDiscountInputChange = (fieldName, value) => {
    setDiscountFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", marginTop: 2 }}>
        Manage Products
      </Typography>
      <Box
        className="ven_products_container"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          position: "relative",
          flexWrap: "wrap",
          gap: 1,
          width: "100%",
        }}
      >
        {venProductData.length > 0 ? (
          venProductData.map((product, i) => (
            <ProductCard
              key={i}
              product={product}
              onEdit={() => handleEditProduct(product._id)}
              onManageDiscount={() => handleManageDiscount(product._id)}
              onDelete={() => handleDeleteProduct(product._id)}
            />
          ))
        ) : (
          <div>No products</div>
        )}
      </Box>
      <Dialog open={editDialogOpen} onClose={handleCancelEdit}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minWidth: 500,
          }}
        >
          <TextField
            label="Name"
            value={selectedProduct?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
            fullWidth
          />
          <TextField
            label="Price"
            value={selectedProduct?.price || ""}
            onChange={(e) => handleInputChange("price", e.target.value)}
            fullWidth
          />
          <TextField
            label="Weight"
            value={selectedProduct?.weight || ""}
            onChange={(e) => handleInputChange("weight", e.target.value)}
            fullWidth
          />
          <TextField
            label="Measurements"
            value={selectedProduct?.measurements || ""}
            onChange={(e) => handleInputChange("measurements", e.target.value)}
            fullWidth
          />
          <TextField
            label="Contents"
            value={selectedProduct?.contents || ""}
            onChange={(e) => handleInputChange("contents", e.target.value)}
            fullWidth
          />
          <TextField
            label="Description"
            value={selectedProduct?.description || ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <Button onClick={handleSaveEdit}>Save</Button>
        </DialogActions>
        {/* Discount Dialog */}
      </Dialog>
      <Dialog open={discountDialogOpen} onClose={handleCancelDiscount}>
        <DialogTitle>Apply Discount</DialogTitle>
        <DialogContent>
          <TextField
            label="Discount (%)"
            value={discountFormData.discount}
            onChange={(e) =>
              handleDiscountInputChange("discount", e.target.value)
            }
            fullWidth
          />
          <TextField
            label="Duration (days)"
            value={discountFormData.durationInDays}
            onChange={(e) =>
              handleDiscountInputChange("durationInDays", e.target.value)
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDiscount}>Cancel</Button>
          <Button onClick={handleApplyDiscount}>Apply Discount</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
