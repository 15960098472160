import React from "react";
import { Box } from "@mui/material";
import AddProduct from "../../adminComponents/venAdminComponents/AddProductForm.tsx";
import useAdminStore from "../../../features/zustandStore/sellerAdminStore.js";
import ManageProducts from "../../adminComponents/venAdminComponents/ManageProducts.tsx";

const AdminComponent = () => {
  const currentComponent = useAdminStore((state) => state.currentComponent);
  console.log(currentComponent);

  if (currentComponent === "home") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          overflowY: "auto",
          padding: "0 20px",
        }}
      >
        <h1>Home</h1>
      </Box>
    );
  }
  if (currentComponent === "add product") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: "80vh",
          height: "100%",
          overflowY: "auto",
          zIndex: "0",
          padding: "0 20px",
        }}
      >
        <AddProduct />
      </Box>
    );
  }
  if (currentComponent === "my products") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",

          padding: "0 20px",
          height: "100%",
        }}
      >
        <ManageProducts />
      </Box>
    );
  }
};

export default AdminComponent;
