import React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";

const steps = ["Shipping Address", "Personalization", "Payment"];

interface Props {
  activeStep: number;
}

export default function HorizontalLinearAlternativeLabelStepper({
  activeStep,
}: Props) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
