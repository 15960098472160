const getRandomProducts = (products) => {
  const validatedProducts = [];

  for (let i = 0; i < products.length; i++) {
    const product = products[i];

    if (product.imgLink && product.item && product.retail) {
      validatedProducts.push(product);
    }
  }

  return validatedProducts;
};

export default getRandomProducts;
