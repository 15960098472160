import React, { useState } from "react";
import { storage } from "../../../firebase/firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";

import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import useAuthStore from "../../../features/zustandStore/authStore";

const token = Cookies.get("token");

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
  withCredentials: true,
});

const ProductForm = () => {
  const email = useAuthStore((state) => state.authedEmail);
  console.log(email);
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    category: "", // Added category field
    images: "",
    weight: "",
    measurements: "",
    sku: "",
    contents: "",
    productOwner: email,
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [image, setImage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const categories = [
    "Candy",
    "Kids",
    "Birthday",
    "For Her",
    "For Him",
    "Corporate",
    "Get Well",
    "Kosher",
    "Healthy",
    "Baby",
    "Canadian",
    "Alcohol",
    "Wine",
    "Pet Lover",
    "Father's Day",
    "Mother's Day",
    "Gift Packages",
    "Spa",
    "Chocolate",
    "Valentine's Day",
    // Add more categories as needed
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");
    console.log(token); // Ensure you get the latest token value

    try {
      await handleImageUpload();
      console.log(formData);
      const response = await baseApi.post("/ven-products", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Set the Authorization header here
        },
      });
      console.log(response);
      setSuccessMessage(response.data.message);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.response.data);
    }
    // Add any validation logic here before submitting
  };

  async function handleImageUpload() {
    if (image == null) return;
    const imageRef = ref(storage, `images/${image.name + uuid()}`);

    try {
      const snapshot = await uploadBytes(imageRef, image);
      const imgUrl = await getDownloadURL(snapshot.ref);
      formData.images = imgUrl;
      console.log("Uploaded a blob or file!" + imgUrl);
    } catch (error) {
      console.log("Uploaded a blob or file!" + imgUrl);
    }
  }

  return (
    <Paper
      sx={{
        padding: 2,
        maxWidth: "100%",
        width: "100%",
        marginTop: 40,
        textAlign: "center",
        overflow: "auto",
      }}
      elevation={3}
    >
      <Typography variant="h5" gutterBottom>
        Add Product
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <TextField
          label="Product Name"
          name="name"
          variant="outlined"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          label="Price"
          name="price"
          type="number"
          variant="outlined"
          value={formData.price}
          onChange={handleChange}
          required
        />
        <TextField
          label="SKU"
          name="sku"
          variant="outlined"
          value={formData.sku}
          onChange={handleChange}
          required
        />
        <TextField
          label="Description"
          name="description"
          multiline
          rows={4}
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
          required
        />
        <TextField
          label="Contents"
          name="contents"
          variant="outlined"
          value={formData.contents}
          onChange={handleChange}
          required
        />
        <TextField
          label="Weight"
          name="weight"
          type="text"
          variant="outlined"
          value={formData.weight}
          onChange={handleChange}
          required
        />
        <TextField
          label="Measurements"
          name="measurements"
          type="text"
          variant="outlined"
          value={formData.measurements}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            label="Category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="h6" sx={{ marginTop: 2, textAlign: "left" }}>
          Upload image of product
        </Typography>
        <TextField
          type="file"
          name="images"
          onChange={(e) => setImage(e.target.files[0])}
          fullWidth
          margin="normal"
        />
        {errorMessage && (
          <Typography variant="body1" color="error">
            {errorMessage}
          </Typography>
        )}
        {successMessage && (
          <Typography variant="body1" color="success">
            {successMessage}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
          onClick={handleSubmit} // Use onClick for Button
        >
          Submit
        </Button>
      </Box>
    </Paper>
  );
};

export default ProductForm;
