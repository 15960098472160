import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

interface ShippingFormProps {
  onNext: () => void;
  onBack: () => void;
}

const ShippingForm: React.FC<ShippingFormProps> = ({ onNext, onBack }) => {
  const [occasion, setOccasion] = useState("");
  const [locationType, setLocationType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");

  const handleNextClick = () => {
    // Perform form validation here
    if (
      !occasion ||
      !locationType ||
      !firstName ||
      !lastName ||
      !streetAddress ||
      !zipCode ||
      !city
    ) {
      // Show an error message or prevent moving to the next step
      alert("Please fill in all required fields.");
      return;
    }

    onNext();
  };

  const handleBackClick = () => {
    onBack();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0 10em",
        gap: "1em",
        width: "100%",
        "@media (min-width: 1900px)": {
          padding: "2em 20em",
        },
        "@media (max-width: 1024px)": {
          padding: "2em 8em",
          ".content": {
            p: {
              color: "rgba(variables.$primary_black, 0.6)",
            },
          },
        },
        "@media (max-width: 728px)": {
          width: "100%",
          padding: "2em 20px",
          a: {
            svg: {
              width: "100%",
            },
          },
          ".content": {
            p: {
              color: "rgba(variables.$primary_black, 0.6)",
            },
          },
        },
      }}
    >
      <h2>Shipping Form</h2>
      {/* Additional Inputs */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: "100%",
          }}
        >
          <FormControl fullWidth required>
            <InputLabel>Select an Occasion</InputLabel>
            <Select
              label="Select an Occasion"
              value={occasion}
              onChange={(e) => setOccasion(e.target.value as string)}
              fullWidth
              required
            >
              <MenuItem value="birthday">Birthday</MenuItem>
              <MenuItem value="christmas">Christmas</MenuItem>
              <MenuItem value="halloween">Halloween</MenuItem>
              <MenuItem value="anniversary">Anniversary</MenuItem>
              <MenuItem value="thanksgiving">Thanksgiving</MenuItem>
              <MenuItem value="valentines">Valentines</MenuItem>
              <MenuItem value="mother's day">Mother&apos;s Day</MenuItem>
              <MenuItem value="father's day">Father&apos;s Day</MenuItem>
              <MenuItem value="new year's">New Year&apos;s</MenuItem>
              <MenuItem value="easter">Easter</MenuItem>
              <MenuItem value="sympathy">Sympathy</MenuItem>
              <MenuItem value="wedding">Wedding</MenuItem>
              <MenuItem value="thank you">Thank You</MenuItem>
              <MenuItem value="new baby">New Baby</MenuItem>
              <MenuItem value="retirement">Retirement</MenuItem>
              <MenuItem value="sport event">Sport Event</MenuItem>
              <MenuItem value="housewarming">Housewarming</MenuItem>
              <MenuItem value="get well">Get Well</MenuItem>
              <MenuItem value="thinking of you">Thinking of You</MenuItem>
              <MenuItem value="hanukkah">Hanukkah</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth required>
            <InputLabel>Select Location Type</InputLabel>
            <Select
              label="Select Location Type"
              value={locationType}
              onChange={(e) => setLocationType(e.target.value as string)}
              fullWidth
              required
            >
              <MenuItem value="home/residence">Home/Residence</MenuItem>
              <MenuItem value="office">Office</MenuItem>
              <MenuItem value="hospital">Hospital</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="school">School</MenuItem>
              <MenuItem value="church">Church</MenuItem>
              <MenuItem value="PO box">PO Box</MenuItem>
              {/* Add more location types as needed */}
            </Select>
          </FormControl>

          <TextField
            label="First Name"
            fullWidth
            variant="outlined"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <TextField
            label="Last Name"
            fullWidth
            variant="outlined"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            width: "100%",
          }}
        >
          <TextField
            label="Company"
            fullWidth
            variant="outlined"
            // Company input is not required
          />
          <TextField
            label="Street Address"
            fullWidth
            variant="outlined"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            required
          />
          <TextField
            label="Apartment, Suite Or Other"
            fullWidth
            variant="outlined"
            value={apartment}
            onChange={(e) => setApartment(e.target.value)}
          />
          <TextField
            label="Zip Code/Postal Code"
            fullWidth
            variant="outlined"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            required
          />
          <TextField
            label="City/Town"
            fullWidth
            variant="outlined"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "2em",
          gap: "2em",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleBackClick}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ShippingForm;
