import React, { useState, useEffect } from "react";
import styles from "../styles/partials/itemPage/itemPage.module.css";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateQty,
  proccedCheckoutToggle,
} from "../features/cart/cartSlice.js";
import { product } from "../data/products.js";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { ReactComponent as StarIcon } from "../images/icons/star-icon.svg";
import { ItemIamgeCarousel } from "../components/itemPageComponents/ItemImageCarousel.tsx";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import axios from "axios";
import ReviewForm from "../components/itemPageComponents/reviewForm.tsx";
import { Accordion, Rating } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import AuthButton from "../components/buttonsComponents/AuthButton.tsx";
import InfoAccordion from "../components/itemPageComponents/InfoAccordion.tsx";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
  headers: { "Content-Type": "application/json" },
});

export default function ItemPage() {
  const [quantity, setQuantity] = useState<number>(1);
  const [venProductDetails, setVenProductDetails] = useState({});
  const [reviews, setReviews] = useState([]);
  const { id } = useParams<string>();
  const dispatch = useDispatch();

  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  useEffect(() => {
    async function getReviews() {
      try {
        //fetch reviews
        const response = await baseApi.get("/review/post");

        //exctract reviews from response
        const reviews = response.data;

        //filter the current items reviews
        const currentItemReviews = reviews.filter(
          (item) => item.productId == id
        );
        setReviews(currentItemReviews);
      } catch (error) {
        console.error(error);
      }
    }
    getReviews();
  }, [id]);

  useEffect(() => {
    async function fetchProductDetails() {
      try {
        const response = await baseApi.get(`/get-ven-product/${id}`);
        setVenProductDetails(response);
      } catch (error) {
        console.error("Error fetching product details:", error.message);
      }
    }
    fetchProductDetails();
  }, []);

  useEffect(() => {
    console.log(venProductDetails);
  }, []);

  // extract data for product with its id property
  const productDetails = product
    ? product.find((item) => item.sku === id)
    : venProductDetails.find((item) => item.sku === id);

  const handleAddQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleSubtractQuantity = () => {
    if (quantity <= 1) {
      setQuantity(quantity + 1);
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleReviewPost = async (e) => {
    e.preventDefault();

    const data = {
      reviewTitle: "Great!",
      reviewPost: "this products was so good!",
      reviewRating: 4,
      productId: `${productDetails?.sku}`,
    };

    try {
      const response = await baseApi.post("/review/post", data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.item_page_container}>
      <Link to="/dev">
        <Logo className={styles.logo} />
      </Link>
      <Carousel1 />
      <div className={styles.item_content_container}>
        <div className={styles.item_image_container}>
          <span className={styles.background_color}>
            <img className={styles.item_image} src={productDetails?.imgLink} />
          </span>
        </div>
        <div className={styles.item_information_container}>
          <h4 className={styles.item_product_id}>
            product ID: {productDetails?.sku}
          </h4>
          <h4 className={styles.item_product_name}>{productDetails?.item}</h4>
          <InfoAccordion product={productDetails} productId={id} />
          <div className={styles.price_container}>
            <h5 className={styles.item_price}>
              {productDetails?.currency === "usd"
                ? `${
                    productDetails?.retail
                      ? productDetails?.retail
                      : productDetails?.price
                  } (USD)`
                : `${
                    productDetails?.retail
                      ? productDetails?.retail
                      : productDetails?.price
                  } (CAD)`}
            </h5>
            <span className={styles.rating_container}>
              <StarIcon className={styles.star_icon} />
              <StarIcon className={styles.star_icon} />
              <StarIcon className={styles.star_icon} />
              <StarIcon className={styles.star_icon} />
              <StarIcon className={styles.star_icon} />
            </span>
            <div className={styles.add_cart_container}>
              <div className={styles.qty_container}>
                <label htmlFor="amount">QTY:</label>
                <span className={styles.quantity_container}>
                  <button onClick={handleSubtractQuantity}>-</button>
                  <span>{quantity}</span>
                  <button onClick={handleAddQuantity}>+</button>
                </span>
              </div>
              <button
                type="submit"
                onClick={() => {
                  dispatch(proccedCheckoutToggle(productDetails.sku));
                  dispatch(
                    updateQty({
                      sku: productDetails?.sku,
                      quantity: quantity,
                      price: productDetails?.retail
                        ? productDetails?.retail
                        : productDetails?.price,
                    })
                  );
                }}
              >
                ADD TO CART
              </button>
            </div>
          </div>
          {/* <section className={styles.image_carousel_container}>
            <ItemIamgeCarousel styles={styles} />
          </section> */}
        </div>
      </div>
      <section className={styles.any_gift_section}>
        <h4>Any gift for any occaision</h4>
        <ItemIamgeCarousel styles={styles} />
      </section>
      <section className={styles.review_section}>
        <h3>Reviews</h3>

        <div className={styles.review_container}>
          {reviews.length === 0 ? (
            <p>Be the first to write a review</p>
          ) : (
            reviews.map((item, index) => {
              return (
                <div key={index} className={styles.post_content}>
                  <p className={styles.post_title}>{item?.reviewTitle}</p>
                  <p className={styles.post_post}>{item?.reviewPost}</p>
                  <p className={styles.post_rating}>
                    <Rating value={item.reviewRating} readOnly />
                  </p>
                </div>
              );
            })
          )}
        </div>
        <ReviewForm styles={styles} productId={productDetails?.sku} />
      </section>
      <Carousel1 />
    </div>
  );
}
