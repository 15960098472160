import React from "react";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import EmailInput from "../components/inputs/EmailInput.tsx";
import styles from "../styles/partials/calander/calander.module.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default function Calander() {
  return (
    <div className={styles.calander_container}>
      <Link to="/dev">
        <Logo />
      </Link>
      <Carousel1 />

      <h1>Coming Soon</h1>
      <p>no more last minute shopping with our shopping calander</p>

      <bl>
        <li>plan your seasonal gifts dileveries all year around</li>
        <li>pay and forget</li>
        <li>stress free year round gift shopping</li>
      </bl>

      <p>We&apos;ll let you know when the calander is ready.</p>
      <EmailInput placeholder="Email" styles={styles} button="submit" />
    </div>
  );
}
