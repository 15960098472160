import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, CircularProgress } from "@mui/material";
import styles from "../../styles/partials/adminStyles/admin.module.css";

const baseApi = axios.create({ baseURL: "https://xgiftssapi.onrender.com" });

export default function EmailList() {
  const [emailList, setEmailList] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    getEmailList();
  }, []);

  async function getEmailList() {
    try {
      const response = await baseApi.get("/email-list");
      setEmailList(response.data);
      setLoading(false); // Set loading state to false after data is fetched
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={styles.admin_section}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <h3>
          <b>Email List</b>
        </h3>
        {loading ? ( // Render CircularProgress while loading is true
          <CircularProgress />
        ) : (
          emailList.emailList?.map((email, i) => {
            return <p key={i}>{email}</p>;
          })
        )}
      </Box>
    </div>
  );
}
