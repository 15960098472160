import React from "react";
import SvgColor from "../svg-color/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/admin/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "user",
    path: "/admin/user",
    icon: icon("ic_user"),
  },
  {
    title: "product",
    path: "/admin/product",
    icon: icon("ic_cart"),
  },
  {
    title: "email list",
    path: "/admin/email-list",
    icon: icon("ic_blog"),
  },
  {
    title: "CMS",
    path: "/admin/CMS",
    icon: icon("ic_blog"),
  },
  {
    title: "singn out",
    path: "/dev",
    icon: icon("ic_lock"),
  },
];

export default navConfig;
