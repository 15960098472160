import React from "react";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import styles from "../styles/partials/privacyPolicy/privacyPolicy.module.css";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";

export default function PrivacyPoliciesPage() {
  return (
    <section className={styles.privacy_policy_page}>
      <Link to="/dev">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.privacy_policy_container}>
        <article className={styles.content}>
          <Carousel1 />
          <h1>Privacy Policy</h1>
          <img src={aboutUsBanner} alt="about us" />

          <p>
            This Privacy Policy describes how (&quot;Xgiftss&quot;,
            &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) collects, uses,
            and discloses your personal information when you visit and use our
            website, [Your Website URL] (the &quot;Website&quot;).
          </p>

          <h2>1. Information We Collect</h2>

          <h3>1.1 Personal Information</h3>
          <p>
            We may collect personally identifiable information such as: - Your
            name - Contact information (email address, phone number,
            shipping/billing address) - Payment information (credit card
            details)
          </p>

          <h3>1.2 Non-Personal Information</h3>
          <p>
            We may also collect non-personal information such as: - IP address -
            Browser type - Operating system - Usage patterns on the Website
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>
            We may use your information for the following purposes: - Process
            and fulfill your orders - Communicate with you regarding your orders
            and inquiries - Personalize your experience on the Website - Improve
            and optimize our services - Send promotional emails or newsletters
            (if you consent)
          </p>
          <h2>3. Information Sharing and Disclosure</h2>
          <p>
            We do not sell, trade, or rent your personal information to third
            parties. However, we may share your information with: - Trusted
            service providers assisting in our operations (e.g., payment
            processors, shipping companies) - Legal authorities when required by
            law or to protect our rights
          </p>

          <h2>4. Security</h2>
          <p>
            We take reasonable measures to ensure the security and
            confidentiality of your personal information. However, no method of
            transmission over the internet or electronic storage is entirely
            secure, and we cannot guarantee absolute security.
          </p>

          <h2>5. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to analyze trends,
            administer the Website, and gather demographic information. You can
            manage your preferences regarding cookies through your browser
            settings.
          </p>

          <h2>6. Third-Party Links</h2>
          <p>
            Our Website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these
            third-party websites. Please review the privacy policies of these
            websites for more information.
          </p>

          <h2>7. Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy to reflect changes in our
            practices and services. We will notify you of any significant
            changes by posting the updated Privacy Policy on this page.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions, concerns, or requests regarding this
            Privacy Policy, please contact us at: - info@xgiftss.com
          </p>
          <Carousel1 />
        </article>
      </div>
    </section>
  );
}
