import React from "react";
import styles from "../styles/partials/aboutStyles/aboutStyles.module.css";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";

export default function AboutPage() {
  return (
    <div className={styles.about_page}>
      <Link to="/dev">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.about_container}>
        <div className={styles.about_content}>
          <Carousel1 />
          <h1>ABOUT US</h1>
          <img src={aboutUsBanner} alt="about us" />
          <section>
            <h2>Our Story</h2>
            <p>
              At Xgiftss.com, we believe that gifting is an art, and each gift
              should be a reflection of thought, care, and appreciation. Founded
              in 2023, our mission is to make gift-giving an unforgettable
              experience for both the giver and the recipient. We understand
              that finding the perfect gift can be a challenge, which is why
              we&apos;re here to simplify the process and bring joy to every
              occasion.
            </p>
          </section>
          <section>
            <h2>What We Offer</h2>
            <p>
              <strong>A World of Gift Ideas:</strong> Our platform curates an
              extensive collection of unique and thoughtful gifts for all
              occasions. Whether you&apos;re celebrating a birthday,
              anniversary, graduation, or just want to show someone you care,
              we&apos;ve got you covered.
            </p>
            <p>
              <strong>Personalization:</strong> We believe that personal touches
              make gifts even more special. Many of our products can be
              customized with names, messages, or photos, allowing you to create
              one-of-a-kind presents that will be cherished forever.
            </p>
            <p>
              <strong>Quality and Satisfaction:</strong> We take pride in
              offering high-quality products that are sure to delight. Your
              satisfaction is our top priority, and we stand by the quality of
              every item we sell.
            </p>
            <p>
              <strong>Easy Shopping:</strong> Our user-friendly website is
              designed to make your shopping experience as convenient as
              possible. You can browse by occasion, recipient, or product
              category to find the perfect gift quickly.
            </p>
            <p>
              <strong>Customer Support:</strong> Have a question or need
              assistance? Our dedicated customer support team is here to help.
              Contact us via email, and we&apos;ll provide the assistance you
              need promptly.
            </p>
          </section>
          <section>
            <h2>Our Values</h2>
            <ul>
              <li>
                <strong>Quality:</strong> We are committed to delivering
                top-quality gifts that exceed your expectations.
              </li>
              <li>
                <strong>Creativity:</strong> We embrace innovation and
                continuously search for unique and creative gift ideas.
              </li>
              <li>
                <strong>Customer Happiness:</strong> Your satisfaction and
                happiness are at the core of our business.
              </li>
              <li>
                <strong>Affordability:</strong> We believe that heartfelt gifts
                should be accessible to everyone, so we offer a range of
                products to suit every budget.
              </li>
            </ul>
          </section>
          <section>
            <h2>Join Us in Celebrating Life&apos;s Moments</h2>
            <p>
              At Xgiftss.com, we believe that every moment in life is worth
              celebrating, and the right gift can turn an ordinary day into an
              extraordinary memory. Whether you&apos;re shopping for a loved one
              or treating yourself to something special, we invite you to
              explore our collection and experience the joy of giving and
              receiving heartfelt gifts.
            </p>
            <p>
              Thank you for choosing Xgiftss.com as your gift destination. We
              look forward to being part of your special moments and helping you
              create lasting memories.
            </p>
          </section>
          <Carousel1 />
        </div>
      </div>
    </div>
  );
}
