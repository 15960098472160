import React, { useState, useEffect } from "react";
import ProductCard from "../informationComponents/ProductCard.tsx";
import HeaderSection from "./HeaderSection.tsx";
import { useSelector, useDispatch } from "react-redux";
import {
  BirthdayBanner,
  ExploreBanner,
  CategoryBanner,
  Carousel1,
} from "../informationComponents/Banner.tsx";
import {
  setProducts,
  setFilterProducts,
  setSelectedCountry,
} from "../../features/productFilter/filterSlice.js";
import getRandomProducts from "../../functions/getRandomProducts.ts";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { PriceFilter } from "../inputs/PriceFilter.tsx";

interface Props {
  styles: Record<string, string>;
  product: array;
  key: string;
}

export default function ProductSection({ key, styles, product }: Props) {
  const [randomProducts, setRandomProducts] = useState([]);
  const [moreProducts, setMoreProducts] = useState([]);
  const { filterProducts, products } = useSelector((state) => state.filter);
  const selectedCountry = useSelector((state) => state.country.country);
  const selectedPrice = useSelector((state) => state.filter.selectedPrice);
  const dispatch = useDispatch();
  const { category } = useParams();
  const selectedCategory = category;

  useEffect(() => {
    const newProducts = JSON.parse(
      localStorage.getItem("countrySelectedProducts")
    );

    const selectedCategoryProducts = newProducts?.filter((item) => {
      return item.imgFoundIn.includes(category);
    });
    let filteredProducts = selectedCategoryProducts;

    if (selectedPrice > 0) {
      filteredProducts = selectedCategoryProducts?.filter(
        (item) => parseFloat(item.retail.replace("$", "")) <= selectedPrice
      );
    }

    if (category === undefined || null) {
      setRandomProducts(getRandomProducts(filterProducts));
    } else {
      setRandomProducts(getRandomProducts(filteredProducts));
      setMoreProducts(getRandomProducts(products));
    }
  }, [selectedCountry, selectedCategory, selectedPrice]);

  const getLimitedRandomProducts = (arr, limit) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, limit);
  };

  // made this function quickly to give display products in random order
  const getRandomProducts2 = (arr) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled;
  };

  console.log(selectedPrice);

  return (
    <div className={styles.product_section_container}>
      <HeaderSection styles={styles} />

      {getRandomProducts2(randomProducts).length > 0 ? (
        randomProducts.map((item, index) => (
          <React.Fragment key={index}>
            <div className="product_card" key={item.id}>
              <ProductCard product={item} />
            </div>

            {(index + 1) % 16 === 0 && <Carousel1 key={`banner-${index}`} />}
          </React.Fragment>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "225px",
            width: "100vw",
            justifyContent: "center",
            paddingTop: "2em",
          }}
        >
          <p>
            <b>no products</b>{" "}
          </p>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          marginTop: "5em",
        }}
      >
        <h1>MORE PRODUCTS</h1>
      </Box>
      <Carousel1 />
      {getLimitedRandomProducts(moreProducts, 48).map((item, index) => (
        <React.Fragment key={index}>
          <div className="product_card" key={item.id}>
            <ProductCard product={item} />
          </div>
          {(index + 1) % 16 === 0 && <Carousel1 key={`banner-${index}`} />}
        </React.Fragment>
      ))}

      {filterProducts.length % 16 !== 0 && <Carousel1 />}
    </div>
  );
}
