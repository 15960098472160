import React, { useState } from "react";
import CategoryList from "../buttonsComponents/CategoryList.tsx";
import FilterButtonSections from "../productPageComponents/FilterButtonSection.tsx";
import { Link } from "react-router-dom";
import { ReactComponent as XIcon } from "../../images/icons/x-close.svg";
import { Box } from "@mui/material";

interface Props {
  styles: Record<string, string>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function GiftTypeSection({ styles, isOpen, setIsOpen }: Props) {
  const occasionHolidayMenu = {
    header: "Occasions & Holidays",
    listData: {
      holidayList: {
        subHeader: "Occasions/Seasons",
        listItems: {
          birthday: "Birthday",
          congratulations: "Congratulations",
          thankYou: "Thank You",
          graduation: "Graduation",
          sympathy: "Sympathy Gift Baskets",
          getWell: "Get Well",
          thanksgiving: "Thanksgiving&Fall",
          summer: "Summer",
          backToSchool: "Back to School",
          occasions: "Occasions",
          celebration: "Celebration",
        },
      },
      occasionList: {
        subHeader: "Holidays",
        listItems: {
          fathersDay: "Father's Day",
          mothersDay: "Mother's Day",
          halloween: "Halloween",
          housewarming: "Housewarming",
          christmas: "Christmas",
          wedding: "Anniversary&Wedding",
          hanukkah: "Hanukkah",
          newYears: "New Year's",
          easter: "Easter",
        },
      },
    },
  };

  const gourmetList = {
    header: "Gourmet Gift Baskets",
    listData: {
      healthyList: {
        subHeader: "Healthy",
        listItems: {
          freshFruit: "Fresh Fruit",
          organic: "Healthy",
        },
      },

      bakeGiftBaskets: {
        subHeader: "Bakery Gift Baskets",
        listItems: {
          bakedgifts: "Bakery",
          bakedGoods: "Baked Goods",
        },
      },
      drinksAndAlcohol: {
        subHeader: "Drinks/Alcohol",
        listItems: {
          wine: "Wine",
          redWine: "Red Wine",
          whiteWine: "White Wine",
          premiumWine: "Premium Fine Wines",
          champagne: "Champagne",
          tea: "Tea Gift Baskets",
        },
      },
      gourmet: {
        subHeader: "Gourmet & Kosher",
        listItems: {
          gourmet: "Gourmet",
          cheeseMeat: "Cheese & Meat",
          kosher: "Kosher",
        },
      },

      snacks: {
        subHeader: "snacks&candy",
        listItems: {
          cheeseCake: "Cheesecake Gifts",
          snakckBasket: "Snacks",
          candy: "Candy",
          chocolate: "Chocolate",
          meatCheese: "Meat & Cheese",
          comfortFood: "Comfort Food",
        },
      },
    },
  };
  const recipient = {
    header: "Recipient",
    listData: {
      healthyList: {
        subHeader: "Recipient",
        listItems: {
          forHer: "For Her",
          forHim: "For Him",
          kids: "Kids",
          baby: "Baby",
          petLover: "Pet Lover",
          candian: "Canadian",
        },
      },
    },
  };
  const drinks = {
    header: "Alcohol & Other Drinks",
    listData: {
      healthyList: {
        subHeader: "Alcohol & Other Drinks",
        listItems: {
          alcohol: "Alcohol",
          wine: "Wine",
          redWine: "Red Wine",
          whiteWine: "White Wine",
          premiumWines: "Premium Fine Wines",
          roseWine: "Rosé Wine",
          champagne: "Champagne",
        },
      },
    },
  };
  const specialGifts = {
    header: "Special Packages & Baskets",
    listData: {
      healthyList: {
        subHeader: "Special Packages & Baskets",
        listItems: {
          new: "New Gift Baskets",
          giftPackages: "Gift Packages",
          giftTower: "Gourmet Gift Towers",
          carePackages: "Care Package",
          towers: "Towers",
          spa: "Spa",
          snackBasket: "Snack Baskets",
          fruiteBaskets: "Fruit Baskets",
          thankYouBasket: "Thank You Gift Baskets",
          halloweebBasket: "Halloween Gift Baskets",
        },
      },
    },
  };
  const [isClose, setIsClose] = useState(false);

  const handleCloseBtnClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className={styles.content_column_container}>
        <XIcon className={styles.x_icon} onClick={handleCloseBtnClick} />

        {isOpen ? (
          <>
            <CategoryList
              styles={styles}
              optionList={occasionHolidayMenu}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <CategoryList
              styles={styles}
              optionList={gourmetList}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <CategoryList
              styles={styles}
              optionList={recipient}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <CategoryList
              styles={styles}
              optionList={drinks}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <CategoryList
              styles={styles}
              optionList={specialGifts}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <Link to="/corporate-order/corporate">
              <Box
                sx={{
                  height: "80px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  padding: "0 1em",
                }}
              >
                Corprate Order
              </Box>
            </Link>
          </>
        ) : null}
      </div>
    </>
  );
}
