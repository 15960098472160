import React, { useState } from "react";
import axios from "axios";
import { Button } from "@mui/material";

interface Props {
  styles: Record<string, string>;
  placeholder?: string;
  button: string;
}

type emailType = string;

const api = axios.create({
  // baseURL: "http://localhost:3001",
  baseURL: "https://xgiftssapi.onrender.com",
});

const LoadingSpinner = () => {
  return <div className="loading-spinner">Loading...</div>;
};

// ... (existing imports and code)

export default function EmailInput({ styles, placeholder, button }: Props) {
  const [emailData, setEmailData] = useState<emailType>("");
  const [errorSuccess, setErrorSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const errors: Record<string, string> = {};

    // if email is empty
    if (!emailData.trim()) {
      errors.email = "You must enter your email. Please try again.";
    }

    // valid email format
    const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailFormat.test(emailData)) {
      errors.email = "Please enter a valid email address.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; //true if no errors
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setEmailData(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      try {
        const response = await api.post(
          "/newsletterEmail",
          { email: emailData },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setEmailData("");
        setErrorSuccess(response.data);
      } catch (error) {
        console.log(error);
        setErrorSuccess("3");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.shipping_address_input}>
      <form onSubmit={handleSubmit}>
        <input
          className={`form-control form-control-lg ${
            errors.email && "is-invalid"
          }`}
          type="email"
          placeholder={placeholder}
          onChange={handleInput}
          value={emailData}
        />
        <Button
          onClick={handleSubmit}
          type="submit"
          className={styles.input_button}
          variant="contained"
          sx={{ background: "green" }}
        >
          {loading ? <LoadingSpinner /> : button}
        </Button>
      </form>
      {errors.email && <p className="error_or_success">{errors.email}</p>}
      {errorSuccess && !errors.email && (
        <p className="error_or_success">{errorSuccess}</p>
      )}
    </div>
  );
}
