import React from "react";
import { Typography, Container, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate

export default function PendingApplication() {
  const navigate = useNavigate(); // Initialize the navigate function

  // This function will be called when the return button is clicked
  const handleReturn = () => {
    navigate("/dev"); // Navigate to the /dev path
  };

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "400px",
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Application Pending
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          Thank you for submitting your application. We are currently in the
          process of reviewing your details. You will receive an email
          notification once your application has been processed.
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleReturn}
          sx={{ mt: 1 }}
        >
          Return
        </Button>
      </Paper>
    </Container>
  );
}
