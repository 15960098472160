import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategory } from "../../../features/category/categorySlice.ts";
import { setFilterProducts } from "../../../features/productFilter/filterSlice.js";
import { product } from "../../../data/products.js";
import ButtonUnderline from "../../animationComponents/ButtonUnderline.tsx";

interface Props {
  styles: Record<string, string>;
  optionList: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export default function SubList({
  styles,
  optionList,
  isOpen,
  setIsOpen,
}: Props) {
  const dispatch = useDispatch();
  const [categoryClicked, setCategoryClicked] = useState(false);
  const selectedCountry = localStorage.getItem("selectedCountry") || "usa";

  const handleCategoryClick = (category, key) => {
    const selectedCategory = category;
    const selectedCountry = selectedCategory; // Replace with the actual selected country

    dispatch(
      setFilterProducts({
        selectedCategories: selectedCategory,
        selectedCountry: selectedCountry,
      })
    );
    dispatch(setCategory(selectedCategory));

    if (window.innerWidth <= 768) {
      setIsOpen(false);
    }
  };

  return (
    <span className={styles.list_background}>
      {isOpen ? (
        <>
          {Object.values(optionList.listData).map((list, index) => {
            if (!list || !list.listItems) {
              return null;
            }

            return (
              <div className={styles.list_container} key={index}>
                <h5>{list?.subHeader}</h5>
                {Object.entries(list?.listItems).map(([key, value], index) => (
                  <li key={index}>
                    <Link
                      to={`/product-page/${value}`}
                      onClick={() => handleCategoryClick([value], key)}
                    >
                      {value}
                    </Link>
                    <ButtonUnderline styles={styles} />
                  </li>
                ))}
              </div>
            );
          })}
        </>
      ) : null}
    </span>
  );
}
