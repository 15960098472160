import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Tabs, Tab } from "@mui/material";
import styles from "../styles/partials/corporateOrderStyles/corporateOrderStyles.module.css";
import corporateOrderForm from "../images/corporateOrderForm.xlsx";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import aboutUsBanner from "../images/x-aboutus-banner.png";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import ProductSection from "../components/productPageComponents/ProductSection.tsx";

function CorporateOrderPage() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <section className={styles.corporate_order_container}>
      <Link className={styles.logo_a} to="/dev">
        <Logo />
      </Link>

      <Carousel1 />

      <h1>LARGE/CORPORATE ORDER</h1>
      {/* Add Tabs component here */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="corporate order tabs"
        >
          <Tab label="Order Form" />
          <Tab label="Products" />
        </Tabs>
      </Box>

      {/* Render content based on selected tab */}
      {tabValue === 0 && (
        <>
          {/* Content for Tab 1 */}
          <img src={aboutUsBanner} alt="about us" />

          <a
            className={styles.download_form}
            href={corporateOrderForm}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button variant="contained">Cick Here To Download Form</Button>
          </a>
          <p>Once you’ve chosen your basket, you have two options:</p>

          <ol>
            <li>
              <strong>Use Your Own Address List</strong> - Send an email to{" "}
              <a id="mail-link" href="mailto:corporateorder@xgiftss.com">
                corporateorder@xgiftss.com
              </a>{" "}
              with your own list of recipient addresses in an Excel or Word
              file.
            </li>
            <li>
              <strong>Use Our Excel Large Order Form</strong> - To receive our
              Excel form{" "}
              <a
                href="https://cdn.shopify.com/s/files/1/0381/6997/files/Big-Order-2022-FINAL.xlsx?v=1679587039"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here to download
              </a>{" "}
              and send us an email to{" "}
              <a id="mail-link" href="mailto:corporateorder@xgiftss.com">
                corporateorder@xgiftss.com
              </a>{" "}
              with the completed form attached.
            </li>
          </ol>

          <h3>
            Please make sure to provide the following information in your
            recipient list:
          </h3>

          <bl>
            <li>Gift basket name & price</li>
            <li>Billing contact information</li>
            <li>
              Recipient information - <strong>Full name</strong>,{" "}
              <strong>address</strong>, <strong>postal code</strong>, and{" "}
              <strong>phone number</strong>
            </li>
            <li>Custom greeting card message</li>
            <li>Delivery date range (ie. Dec 5th - Dec 12th)</li>
            <li>Payment method</li>
          </bl>

          <p>
            We will print your message on all greeting cards and attach them to
            the appropriate basket, so don’t forget to include the card message!
          </p>

          <p>
            <strong>
              IMPORTANT - We send confirmation within 24 hours - IMPORTANT
            </strong>
          </p>

          <p>
            Once we receive your order information we will provide you a quote
            within <strong>24 hours</strong>.
          </p>

          <p>
            If you <strong>do not</strong> receive a confirmation email within
            24 hours, please email us at{" "}
            <a id="mail-link" href="mailto:corporateorder@xgiftss.com">
              corporateorder@xgiftss.com
            </a>
          </p>

          <p>
            If you have any questions you can also email us at{" "}
            <a id="mail-link" href="mailto:corporateorder@xgiftss.com">
              corporateorder@xgiftss.com
            </a>
          </p>
        </>
      )}

      {tabValue === 1 && (
        <div>
          <h1>Corporate Products</h1>

          <ProductSection styles={styles} />
        </div>
      )}

      <Carousel1 />
    </section>
  );
}

export default CorporateOrderPage;
