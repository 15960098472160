// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAUHgaaE-3t3psgbYfzNy6VRyojxZe52pQ",
  authDomain: "xgiftss-73b84.firebaseapp.com",
  projectId: "xgiftss-73b84",
  storageBucket: "xgiftss-73b84.appspot.com",
  messagingSenderId: "260439292417",
  appId: "1:260439292417:web:3c8600e2264195a7681e00",
  measurementId: "G-L6KEFQSHSM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export { storage };
