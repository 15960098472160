import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/partials/informationComponents/banner.module.css";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { setCategory } from "../../features/category/categorySlice.ts";
import { setFilterProducts } from "../../features/productFilter/filterSlice.js";
import { useContentful } from "../../functions/contentful.tsx";

interface Props {
  content: any;
}

function HomeMainBanner() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className="home-banner-1 banner">
      <section className="section_1">
        <div className="section_1_img"></div>
        <div className="section_1_content">
          <div className="text_container">
            <p>Happy Halloween</p>
          </div>
          <Link
            to="/product-page"
            className="banner_link"
            onClick={() => handleCategoryClick("Halloween Gift Baskets")}
          >
            Shop
          </Link>
        </div>
      </section>
    </div>
  );
}

function HomeMainBanner2() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className="home-banner-2 banner">
      <section className="section_2">
        <div className="section_2_img"></div>
        <div className="section_2_content">
          <div className="text_container">
            <p>prepare for the season</p>
          </div>
          <Link
            to="/product-page"
            className="banner_link"
            onClick={() => handleCategoryClick("Halloween Gift Baskets")}
          >
            Shop
          </Link>
        </div>
      </section>
    </div>
  );
}

function BirthdayBanner() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className={`${styles.birthday_banner}  ${styles.banner}`}>
      <section className={styles.section_1}>
        <div className={styles.section_1_img}></div>
        <div className={styles.section_1_content}>
          <div className={styles.text_container}>
            <p>
              <b>shop</b> for birthday
            </p>
          </div>
          <Link
            to="/product-page"
            className={styles.banner_link}
            onClick={() => handleCategoryClick("Birthday")}
          >
            shop
          </Link>
        </div>
      </section>
    </div>
  );
}

function OccasionBanner() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className={`${styles.occasion_banner}  ${styles.banner}`}>
      <section className={styles.section_1}>
        <div className={styles.section_1_img}></div>
        <div className={styles.section_1_content}>
          <div className={styles.text_container}>
            <p>shop for any occassion</p>
          </div>
          <Link
            to="/product-page"
            className={styles.banner_link}
            onClick={() => handleCategoryClick("Halloween Gift Baskets")}
          >
            shop
          </Link>
        </div>
      </section>
    </div>
  );
}

function ExploreBanner() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className={`${styles.explore_banner}  ${styles.banner}`}>
      <section className={styles.header_container}>
        <h4 className={styles.header}>
          Explore Our <b>Gifts</b>
        </h4>
      </section>
      <ul className={styles.explore_list}>
        <li className={styles.list_item}>
          <Link
            to="/product-page"
            className={styles.item_img}
            onClick={() => handleCategoryClick("Halloween Gift Baskets")}
          >
            <div className={`${styles.item_img} ${styles.season}`}></div>
          </Link>
        </li>
        <li className={styles.list_item}>
          <Link
            to="/product-page"
            className={styles.item_img}
            onClick={() => handleCategoryClick("Alcohol")}
          >
            <div className={`${styles.item_img} ${styles.wedding}`}></div>
          </Link>
        </li>
        <li className={styles.list_item}>
          <Link
            to="/product-page"
            className={styles.item_img}
            onClick={() => handleCategoryClick("Birthday")}
          >
            <div className={`${styles.item_img} ${styles.birthday}`}></div>
          </Link>
        </li>
        <li className={styles.list_item}>
          <Link
            to="/product-page"
            className={styles.item_img}
            onClick={() => handleCategoryClick("Baked Goods")}
          >
            <div className={`${styles.item_img} ${styles.gourmet}`}></div>
          </Link>
        </li>
      </ul>
    </div>
  );
}

function Banner() {
  return <div className={styles}></div>;
}

function TasteCanadaBanner() {
  const dispatch = useDispatch();

  const handleCategoryClick = (category) => {
    dispatch(setCategory([category]));
    dispatch(setFilterProducts([category]));
  };
  return (
    <div className={`${styles.taste_canada_banner}  ${styles.banner}`}>
      <section className={styles.img_container}>
        <div className={styles.left_branch_img}></div>
      </section>
      <section className={styles.left_leaf_container}>
        <div className={styles.left_top_leaf_img}></div>
        <div className={styles.left_bottom_leaf_img}></div>
      </section>
      <section className={styles.content_container}>
        <h1>Explore the</h1>
        <h1>
          taste of{" "}
          <span>
            <b>Canada</b>
          </span>
        </h1>
        <Link
          to="/product-page"
          onClick={() => handleCategoryClick("Canadian")}
        >
          shop
        </Link>
      </section>
      <section className={styles.right_leaf_container}>
        <div className={styles.right_top_leaf_img}></div>
        <div className={styles.right_bottom_leaf_img}></div>
      </section>
      <section className={styles.img_container}>
        <div className={styles.right_branch_img}></div>
      </section>
    </div>
  );
}

function CategoryBanner() {
  const selectedCategory = useSelector(
    (state) => state.category.selectedCategory
  );
  return (
    <div className={`${styles.category_banner}  ${styles.banner}`}>
      <div className={styles.img_container}></div>
      {selectedCategory && <h2>{selectedCategory}</h2>}
    </div>
  );
}

function Banner1({ content }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner1 = content?.map((item) => {
    return item.banner1?.fields;
  });

  const bannerUrl = banner1?.[0]?.file?.url;
  const bannerCategory =
    banner1?.[0]?.title == undefined || null ? "" : banner1?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={(event) => handleCategoryClick(event)}
      className={`${styles.for_her_banner1}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner2({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner2 = content?.map((item) => {
    return item.banner2?.fields;
  });

  const bannerUrl = banner2?.[0]?.file?.url;
  const bannerCategory =
    banner2?.[0]?.title == undefined || null ? "All" : banner2?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}
function Banner3({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner3 = content?.map((item) => {
    return item.banner3?.fields;
  });

  const bannerUrl = banner3?.[0]?.file?.url;
  const bannerCategory =
    banner3?.[0]?.title == undefined || null ? "All" : banner3?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner4({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner4 = content?.map((item) => {
    return item.banner4?.fields;
  });

  const bannerUrl = banner4?.[0]?.file?.url;
  const bannerCategory =
    banner4?.[0]?.title == undefined || null ? "All" : banner4?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner5({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.

  const dispatch = useDispatch();
  const navigate = useNavigate();
  //selects the banner that's wanted
  const banner5 = content?.map((item) => {
    return item.banner5?.fields;
  });

  const bannerUrl = banner5?.[0]?.file?.url;
  const bannerCategory =
    banner5?.[0]?.title == undefined || null ? "All" : banner5?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner6({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner6 = content?.map((item) => {
    return item.banner6?.fields;
  });

  const bannerUrl = banner6?.[0]?.file?.url;
  const bannerCategory =
    banner6?.[0]?.title == undefined || null ? "All" : banner6?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner7({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner7 = content?.map((item) => {
    return item.banner7?.fields;
  });

  const bannerUrl = banner7?.[0]?.file?.url;
  const bannerCategory =
    banner7?.[0]?.title == undefined || null ? "All" : banner7?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner8({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner8 = content?.map((item) => {
    return item.banner8?.fields;
  });

  const bannerUrl = banner8?.[0]?.file?.url;
  const bannerCategory =
    banner8?.[0]?.title == undefined || null ? "All" : banner8?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}

function Banner9({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner9 = content?.map((item) => {
    return item.banner9?.fields;
  });

  const bannerUrl = banner9?.[0]?.file?.url;
  const bannerCategory =
    banner9?.[0]?.title == undefined || null ? "All" : banner9?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}
function Banner10({ content }: Props) {
  //   //this will references the content type "banner#2" from contentful.
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //selects the banner that's wanted
  const banner10 = content?.map((item) => {
    return item.banner10?.fields;
  });

  const bannerUrl = banner10?.[0]?.file?.url;
  const bannerCategory =
    banner10?.[0]?.title == undefined || null ? "All" : banner10?.[0]?.title;

  const handleCategoryClick = (event) => {
    event.preventDefault();
    navigate(`/product-page/${bannerCategory}`);
  };

  return (
    <Link
      to={`/product-page/${bannerCategory}`}
      onClick={() => handleCategoryClick(event)}
      className={`${styles.for_her_banner2}  ${styles.banner}`}
    >
      <img src={bannerUrl} alt="" className={styles.img_container}></img>
    </Link>
  );
}
//located: [first carousel:  homePage;]
function Carousel1() {
  const [content, setContent] = useState([]);
  const { getContent } = useContentful();

  useEffect(() => {
    useContentful().then(async (res) => {
      const data = await res.getContent();
      setContent(data);
    });
  }, []);

  const banners = [
    <Banner1 key="banner#1" content={content} />,
    <Banner2 key="banner#2" content={content} />,
    <Banner3 key="banner#3" content={content} />,
    <Banner4 key="banner#4" content={content} />,
    <Banner5 key="banner#5" content={content} />,
    <Banner6 key="banner#6" content={content} />,
    <Banner7 key="banner#7" content={content} />,
    <Banner8 key="banner#8" content={content} />,
    <Banner9 key="banner#9" content={content} />,
    <Banner10 key="banner#10" content={content} />,
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  const shuffledBanners = [...banners].sort(() => Math.random() - 0.5);

  return (
    <Slider {...settings} className={`${styles.banner}`}>
      {shuffledBanners.map((img, index) => {
        return img;
      })}
    </Slider>
  );
}
//located: [every 4 rows:  productPage, first carousel:  productPage;]
function Carousel2() {
  const banners = [<BirthdayBanner key="birthday-banner" />];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  const shuffledBanners = [...banners].sort(() => Math.random() - 0.5);

  return (
    <Slider {...settings} className={`${styles.banner}`}>
      {shuffledBanners.map((img, index) => {
        return img;
      })}
    </Slider>
  );
}
//located: [first carousel:  itemPage;]
function Carousel3() {
  const banners = [<TasteCanadaBanner key="taste-canada-banner" />];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };
  const shuffledBanners = [...banners].sort(() => Math.random() - 0.5);

  return (
    <Slider {...settings} className={`${styles.banner}`}>
      {shuffledBanners.map((img, index) => {
        return img;
      })}
    </Slider>
  );
}

export {
  Banner,
  HomeMainBanner,
  BirthdayBanner,
  OccasionBanner,
  ExploreBanner,
  TasteCanadaBanner,
  HomeMainBanner2,
  CategoryBanner,
  Carousel1,
};
