/* eslint-disable react/no-unknown-property */
import React from "react";
import { ReactComponent as LeftArrow } from "../../images/left-arrow.svg";

interface Props {
  onClick: () => void;
}

export default function PrevArrow({ onClick }: Props) {
  return <LeftArrow className="slick-prev" onClick={onClick} />;
}
