import React, { useState } from "react";
import { ReactComponent as XIcon } from "../../images/icons/x-close.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { proccedCheckoutToggle } from "../../features/cart/cartSlice.js";

const ProccedToCheckButton = () => {
  const dispatch = useDispatch();
  const { proccedToCheckout, cartItems } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const itemQty = cartItems.map((item) => {
    item.sku == proccedToCheckout.sku ? item.quantity : null;
  });

  const checkout = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://xgiftssapi.onrender.com/checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: cartItems }),
      });
      const data = await response.json();
      if (data.url) {
        window.location.assign(data.url);
      }
    } catch (error) {
      console.error(error);
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  };

  if (proccedToCheckout.toggle == false) {
    return null;
  }

  return (
    <section className="boundry_container">
      <div className="procced_to_check_out_container">
        <div className="procced_header_container">
          <h5>SHOPPING CART</h5>
          <XIcon
            onClick={() => {
              dispatch(proccedCheckoutToggle());
            }}
          />
        </div>
        <div className="procced_detail_container">
          <img
            className="procced_item_image"
            src={proccedToCheckout.imgLink}
          ></img>
          <div className="procced_content_container">
            <p className="procced_item_name">{proccedToCheckout.itemName}</p>

            {cartItems.map((item) => {
              if (item.sku === proccedToCheckout.sku) {
                return <p key={item.sku}>QTY: {item.quantity}</p>;
              }
            })}

            <p>{proccedToCheckout.itemPrice}</p>
          </div>
        </div>
        <div className="procced_button_container">
          {/* <Link onClick={checkout} disabled={loading}>
            {loading ? "Loading..." : "CHECKOUT NOW"}
          </Link> */}
          <Link
            to="/cart"
            onClick={() => {
              dispatch(proccedCheckoutToggle());
            }}
          >
            VIEW OR EDIT CART
          </Link>
          <Link
            onClick={() => {
              dispatch(proccedCheckoutToggle());
            }}
          >
            CONTINUE SHOPPING
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProccedToCheckButton;
