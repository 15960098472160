import React from "react";
import { createClient } from "contentful";

const useContentful = async () => {
  const client = createClient({
    space: `${process.env.REACT_APP_CONTENTFUL_SPACE}`,
    accessToken: `${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    host: `${process.env.REACT_APP_CONTENTFUL_HOST}`,
  });

  const getContent = async () => {
    try {
      const entries = await client.getEntries();

      const sanitizedEntries = entries.items.map((item) => {
        return item.fields;
      });

      return sanitizedEntries;
    } catch (error) {
      console.log(error.message);
    }
  };
  return { getContent };
};

export { useContentful };
