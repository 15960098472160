import React from "react";
import { Link } from "react-router-dom";
import ButtonUnderline from "../animationComponents/ButtonUnderline.tsx";

interface Props {
  text: string;
  to: string;
  styles: Record<string, string>;
}

export default function AncorTag({ text, to, styles }: Props) {
  return (
    <div className={styles.ancor_container}>
      <Link to={to} className={styles.ancor_tag}>
        {text}
      </Link>
      <ButtonUnderline styles="underline" />
    </div>
  );
}

// styles are located in styles.scss
