import { createSlice } from "@reduxjs/toolkit";
import { product } from "../../data/products.js";

const cartItems =
  localStorage.getItem("cartItem") !== null
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [];
let cartAmount =
  localStorage.getItem("cartAmount") !== null
    ? parseInt(localStorage.getItem("cartAmount"))
    : 0; // Initialize to 0 if not present
const total =
  localStorage.getItem("total") != null
    ? parseFloat(localStorage.getItem("total"))
    : 0;
const setCartList = (items, cartAmount, total) => {
  localStorage.setItem("cartItem", JSON.stringify(items));
  localStorage.setItem("cartAmount", JSON.stringify(cartAmount));
  localStorage.setItem("total", JSON.stringify(total));
};

if (cartItems.length !== cartAmount) {
  cartAmount = cartItems.length;
  // Update localStorage with the correct cartAmount
  localStorage.setItem("cartAmount", JSON.stringify(cartAmount));
}

const initialState = {
  cartItems: cartItems,
  cartAmount: cartAmount,
  total: total,
  proccedToCheckout: {
    toggle: false,
    sku: "",
    itemName: "",
    itemPrice: "",
    itemQty: "",
    imgLink: "",
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setTotal: (state, action) => {
      const { sku, shippingPrice, filteredRetailPrices } = action.payload;
      const newShippingPrice = Number(shippingPrice);
      const retailPrice = parseFloat(filteredRetailPrices[0].replace("$", ""));

      // Find the specific item in the cart and update its selected shipping price
      const updatedCartItems = state.cartItems.map((item) => {
        if (item.sku === sku) {
          item.price = retailPrice + Number(newShippingPrice);
          item.price = "$" + item.price.toFixed(2);

          item.price =
            typeof item.price === "string" || typeof item.price === "number"
              ? item.price
              : item.price;
        }
        return item;
      });

      // Calculate the total based on all the items' prices and selected shipping prices
      const total = updatedCartItems.reduce((acc, cartItem) => {
        return (
          acc +
          parseFloat(cartItem.price.replace("$", "")) * cartItem.quantity +
          (cartItem.selectedShippingPrice || 0)
        );
      }, 0);

      state.total = parseFloat(total.toFixed(2));

      setCartList(state.cartItems, updatedCartItems, state.total);
    },

    changeQuantity: (state, action) => {
      const { sku, quantity } = action.payload;
      const item = state.cartItems.find((item) => item.sku === sku);

      if (item) {
        const oldQuantity = item.quantity;
        const itemPrice = parseFloat(item.price.replace("$", ""));
        const newTotal = state.total - itemPrice * (oldQuantity - quantity);

        if (quantity === 0) {
          // Remove the item from the cart
          state.cartItems = state.cartItems.filter((item) => item.sku !== sku);
        } else {
          // Update the quantity
          item.quantity = quantity;
        }

        state.cartAmount += quantity - oldQuantity;
        state.total = parseFloat(newTotal.toFixed(2));
        setCartList(state.cartItems, state.cartAmount, state.total);
      }
    },

    cartAmountIncrement: (state, action) => {
      const sku = action.payload;

      const exisitingItem = state.cartItems.find((item) => item.sku == sku);
      const newItem = product.find((item) => item.sku == sku);
      console.log(state.cartItems);

      if (!exisitingItem) {
        state.cartItems.push({
          sku: newItem.sku,
          imgLink: newItem.imgLink,
          item: newItem.item,
          price: newItem.retail,
          description: newItem.description,
          quantity: 1,
          selectedShippingPrice: newItem.selectedShippingPrice,
          shipsTo: newItem.shipsTo,
          shippingMethods: newItem.shippingMethods,
        });

        state.cartAmount += 1;
        state.total += parseFloat(newItem.retail.replace("$", ""));
      } else {
        exisitingItem.quantity += 1;
        state.total += parseFloat(newItem.retail.replace("$", ""));
        state.cartAmount += 1;
      }

      state.total = parseFloat(state.total.toFixed(2));
      setCartList(
        state.cartItems.map((item) => item),
        state.cartAmount,
        state.total
      );
    },

    clearCart: (state, action) => {
      state.cartItems = [];
      state.cartAmount = 0;
      state.total = 0;

      setCartList(state.cartItems, state.cartAmount, state.total);
    },

    cartAmountDecrement: (state, action) => {
      if (state.cartAmount >= 0) state.cartAmount -= 1;

      setCartList(state.cartItems, state.cartAmount, state.total);
    },

    updateQty: (state, action) => {
      //destructure payload to get sku and quantity
      const { sku, quantity, price } = action.payload;

      //check if the item already exists in the cart
      const item = state.cartItems.find((item) => item.sku === sku);
      //find the item in the product array
      const newItem = product.find((item) => item.sku === sku);

      console.log(newItem);

      if (!item) {
        state.cartItems.push({
          sku: newItem.sku,
          imgLink: newItem.imgLink,
          item: newItem.item,
          price: newItem.retail.replace("$", ""),
          description: newItem.description,
          quantity: quantity,
          selectedShippingPrice: newItem.selectedShippingPrice,
          shipsTo: newItem.shipsTo,
          shippingMethods: newItem.shippingMethods,
        });
        state.total += newItem.retail.replace("$", "") * quantity;
        state.cartAmount += quantity;
      } else {
        state.cartAmount += quantity;
        state.total += parseFloat(price.replace("$", "")) * quantity;
        state.total = parseFloat(state.total.toFixed(2));
        item.quantity += quantity;
      }

      setCartList(state.cartItems, state.cartAmount, state.total);
    },
    proccedCheckoutToggle: (state, action) => {
      if (action.payload == null) {
        state.proccedToCheckout.toggle = !state.proccedToCheckout.toggle;
      } else {
        const exisitingItem = product.find(
          (item) => item.sku == action.payload
        );
        // const item = state.cartItems.map((item) => item.sku == action.payload);

        state.proccedToCheckout.sku = exisitingItem.sku;
        state.proccedToCheckout.itemName = exisitingItem.item;
        state.proccedToCheckout.itemPrice = exisitingItem.retail;
        // state.proccedToCheckout.itemQty == .quantity;
        state.proccedToCheckout.imgLink = exisitingItem.imgLink;
        state.proccedToCheckout.toggle = !state.proccedToCheckout.toggle;
        console.log(cartItems);
      }
    },
    changeShippingMethod: (state, action) => {
      const { sku, newShippingMethod, selectedCountry } = action.payload; // Add selectedCountry
      const item = state.cartItems.find((item) => item.sku === sku);

      if (item) {
        // Update the shipping method for the selected item
        item.selectedShippingMethod = newShippingMethod;

        // Recalculate the total based on the new shipping method price
        const shippingMethodPrice =
          parseFloat(
            item.shippingMethods[selectedCountry].find(
              (method) => method.name === newShippingMethod
            )?.price
          ) || 0;

        state.total += shippingMethodPrice * item.quantity;
        state.total = parseFloat(state.total.toFixed(2));

        setCartList(state.cartItems, state.cartAmount, state.total);
      }
    },
  },
});

export const {
  cartAmountIncrement,
  cartAmountDencrement,
  clearCart,
  updateQty,
  proccedCheckoutToggle,
  changeQuantity,
  removeItem, // export removeItem action
  changeShippingMethod,
  setTotal,
} = cartSlice.actions;

export default cartSlice.reducer;
