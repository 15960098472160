import React, { useState, useEffect } from "react";
import { setProducts } from "../../features/productFilter/filterSlice.js";
import { setCountry } from "../../features/countrySlice/countrySlice";
import { useDispatch } from "react-redux";
import styles from "../../styles/partials/navigation/navBar.module.css";

export default function CountrySelect() {
  const dispatch = useDispatch();
  const selectedCountry = localStorage.getItem("selectedCountry") || "usa";

  const handleCountryChange = (newCountry) => {
    dispatch(setCountry(newCountry));
    dispatch(setProducts(newCountry));
  };

  return (
    <div className={styles.country_selector}>
      <label>
        <select
          default={selectedCountry}
          onChange={(e) => handleCountryChange(e.target.value)}
        >
          <option value="usa">USA</option>
          <option value="canada">Canada</option>
        </select>
      </label>
    </div>
  );
}
