// SellerLoginPage.js
import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import useAuthStore from "../../features/zustandStore/authStore.js";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com", // Proxy server URL
});

const SellerLoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginError, setLoginError] = useState(null);
  const [loading, setLoading] = useState(false); // Introduce loading state
  const navigate = useNavigate();
  const { setAuthedEmail, sellerAuth, setSellerAuth } = useAuthStore();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const getExpirationDate = (minutes) => {
      const expiresIn = minutes * 60;
      const expirationDate = new Date(Date.now() + expiresIn * 1000);
      return expirationDate.toUTCString();
    };

    try {
      setLoading(true);

      const response = await baseApi.post("/seller-login", formData);
      const data = response.data;

      // Handle successful login
      console.log(response.data);

      setAuthedEmail(formData.email);
      setSellerAuth(true);
      document.cookie = `token=${data.token}; expires=${getExpirationDate(15)}`;
      navigate("/seller-dashboard");
    } catch (error) {
      console.error("Login Error:", error.message);
      setLoginError("Invalid email or password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        marginTop: 8,
        height: "80vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Logo />
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Seller Login
        </Typography>

        <form onSubmit={handleLogin}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleChange}
            disabled={loading} // Disable the input during loading
          />
          <TextField
            margin="normal"
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            disabled={loading} // Disable the input during loading
          />
          {loginError && (
            <Typography variant="body2" color="error" sx={{ marginTop: 1 }}>
              {loginError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
            disabled={loading} // Disable the button during loading
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : (
              "Login"
            )}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SellerLoginPage;
