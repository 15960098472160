import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "../features/cart/cartSlice.js";
import categoryReducer from "../features/category/categorySlice.ts";
import filterReducer from "../features/productFilter/filterSlice.js";
import countryReducer from "../features/countrySlice/countrySlice.js";
import orderReducer from "../features/order/orderSlice.js";

export const store = configureStore({
  reducer: {
    country: countryReducer,
    cart: cartReducer,
    category: categoryReducer,
    filter: filterReducer,
    order: orderReducer,
  },
});
