import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../../images/logo-white-text.svg";
import PlacesAutocomplete from "../inputs/ShippingAddressInput.tsx";
import EmailInput from "../inputs/EmailInput.tsx";

interface Props {
  styles: Record<string, string>;
}

export default function MainSection({ styles }: Props) {
  return (
    <div className={styles.main_section}>
      <div className={styles.searchbar_section}>
        {/* <Link to="/dev">
          <LogoWhite className={styles.logo_white} />
        </Link> */}
        {/* <form>
          <PlacesAutocomplete
            placeholder="Start Shopping with shipping address"
            styles={styles}
          />
        </form> */}
      </div>

      {/* <h4>
        Start <b>shopping</b> with the <b>shipping address</b> of the recipent
      </h4> */}
    </div>
  );
}
