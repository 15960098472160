import React, { useEffect } from "react";
import styles from "../../styles/partials/partnerLoginPage/PartnerLoginPage.module.css";
import SearchbarInput from "../inputs/ShippingAddressInput.tsx";
import { Link } from "react-router-dom";
import AuthButton from "../buttonsComponents/AuthButton.tsx";
import fetchAllUsers from "../../functions/userAuthFunctons/fetchAllUsers.ts";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

export default function PageContent() {
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetchAllUsers();
        console.log(response);
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    }

    fetchData();
  }, []);

  return (
    <Container
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Container sx={{ marginTop: "2em", marginBottom: "2em" }}>
        <Typography variant="h4">Become a Partner with</Typography>
        <Link to="/vendor-application-form" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Become a Partner
          </Button>
        </Link>
      </Container>
      <Container>
        <Typography variant="h4">Already a partner?</Typography>
        <Link to="/seller-login" style={{ textDecoration: "none" }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Login here
          </Button>
        </Link>
      </Container>
    </Container>
  );
}
