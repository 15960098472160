import React, { useState } from "react";
import { TextField } from "@mui/material";
import { Rating, Button } from "@mui/material";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  styles: any;
  productId: string;
}

const baseApi = axios.create({ baseURL: "https://xgiftssapi.onrender.com" });

export default function ReviewForm({ styles, productId }: Props) {
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();

  const [reviewData, setReviewData] = useState({
    reviewTitle: "",
    reviewPost: "",
    reviewRating: 0,
    productId: productId,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRatingChange = (newValue) => {
    setReviewData((prevData) => ({
      ...prevData,
      reviewRating: newValue,
    }));
  };

  const handleReviewPost = async (e) => {
    e.preventDefault();

    try {
      const response = await baseApi.post("/review/post", reviewData);
      console.log(response);
      location.reload();
      // You might want to reset the form or update the UI based on the response
    } catch (error) {
      console.error(error);
    }
  };

  // Render the form only if the user is authenticated
  return (
    <form onSubmit={handleReviewPost} className={styles.review_form}>
      <TextField
        label="Title"
        variant="outlined"
        name="reviewTitle"
        value={reviewData.reviewTitle}
        onChange={handleInputChange}
      />
      <TextField
        label="Review"
        variant="outlined"
        multiline
        rows={4}
        name="reviewPost"
        value={reviewData.reviewPost}
        onChange={handleInputChange}
      />
      <div>
        <Rating
          name="reviewRating"
          value={reviewData.reviewRating}
          onChange={(event, newValue) => handleRatingChange(newValue)}
        />
      </div>
      <Button type="submit" variant="text">
        Submit
      </Button>
    </form>
  );
}
