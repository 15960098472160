import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  buttonText: string;
  styles: Record<string, string>;
}

export default function AuthButton({ buttonText, styles }: Props) {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  if (!isAuthenticated) {
    return (
      <div className="auth_btn_container" id="auth-button">
        <button className="auth_btn" onClick={() => loginWithRedirect()}>
          {buttonText}
        </button>
      </div>
    );
  } else if (isAuthenticated) {
    return (
      <div className="auth_btn_container">
        <button
          className="auth_btn"
          onClick={() =>
            logout({
              logoutParams: { returnTo: "https://www.xgiftss.com/dev" },
            })
          }
        >
          log out
        </button>
        {user && <p className="auth_btn_user_name">{user.email}</p>}
      </div>
    );
  }
}
