import React, { useEffect } from "react";
import PartnerLoginPage from "./pages/PartnerLoginPage.tsx";
import ProductPage from "./pages/ProductPage.tsx";
import AccountPage from "./pages/AccountPage.tsx";
import ItemPage from "./pages/ItemPage.tsx";
import CartPage from "./pages/CartPage.tsx";
import NavBar from "./components/navigationComponents/NavBar.tsx";
import Footer from "./components/navigationComponents/Footer.tsx";
import HomePage from "./pages/HomePage.tsx";
import "./styles/styles.css";
import "bootstrap";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ScrollToTop from "./components/navigationComponents/ScrollToTop.tsx";
import LandingPage from "./pages/LandingPage.tsx";
import PrivacyPoliciesPage from "./pages/PrivacyPolicyPage.tsx";
import TermsAndConditions from "./pages/TermsAndConditionsPage.tsx";
import AboutPage from "./pages/AboutPage.tsx";
import ProccedToCheckButton from "./components/informationComponents/ProccedToCheckButton.tsx";
import CheckoutForm from "./components/stripe/CheckoutForm.tsx";
import { useContentful } from "./functions/contentful.tsx";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "./features/productFilter/filterSlice.js";
import CorporateOrderPage from "./pages/CorprateOrderPage.tsx";
import AppBar from "./components/mobileComponents/AppBar.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import Calander from "./pages/Calander.tsx";
import ShipPolicies from "./pages/ShipPolicies.tsx";
import ReturnPolicies from "./pages/ReturnPolicies.tsx";
import FrequentQuestions from "./pages/FrequentQuestions.tsx";
import ItemPolicies from "./components/VendorPolicies/ItemPolicies.tsx";
import CheckoutAuth from "./components/cartComponents/CheckoutAuth.tsx";
import Admin from "./pages/Admin.tsx";
import VendorApplicationForm from "./pages/VenAppForm.tsx";
import VendorApplicationPending from "./pages/VenAppPending.tsx";
import VendorApplicationDenied from "./pages/VenAppDenied.tsx";
import VendorTermsAndConditions from "./pages/VenTerms&Cond.tsx";
import AuthForm from "./components/authComponent/PartnerAuthForm.tsx";
import SellerLoginPage from "./components/authComponent/SellerLoginForm.tsx";
import SellerDashboard from "./components/accountComponents/SellerDashboard.tsx";
import useAuthStore from "./features/zustandStore/authStore.js";
import VenProductDisplay from "./pages/VenProductDisplay.tsx";

function App() {
  const dispatch = useDispatch();
  const selectedCountry = useSelector((state) => state.country.country);
  const mobileActive = useMediaQuery("(max-width: 728px)");

  localStorage.setItem("selectedCountry", selectedCountry);

  const selectedLocalCountry = localStorage.getItem("selectedCountry");

  useEffect(() => {
    dispatch(setProducts(selectedLocalCountry));
  }, []);
  const location = useLocation();

  const isLandingPage = location.pathname === "/";
  const isAdminPage = location.pathname.startsWith("/admin");
  const hasAdminParams = isAdminPage && location.pathname.split("/").length > 2;

  const sellerAuth = useAuthStore((state) => state.sellerAuth);

  return (
    <>
      <ScrollToTop />
      {!isLandingPage && !hasAdminParams && !isAdminPage && <NavBar />}
      <ProccedToCheckButton />
      <Routes>
        <Route path="/dev" element={<HomePage />} />
        <Route path="/product-page/:category" element={<ProductPage />} />
        <Route path="/product-page/All" element={<HomePage />} />
        <Route path="/item-page/:id" element={<ItemPage />} />
        <Route path="/privacy-policies" element={<PrivacyPoliciesPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/shipping-policies" element={<ShipPolicies />} />
        <Route path="/return-policies" element={<ReturnPolicies />} />
        <Route path="/item-policies/:id" element={<ItemPolicies />} />
        <Route path="/frequent-questions" element={<FrequentQuestions />} />
        <Route path="/calendar" element={<Calander />} />
        <Route path="/admin/:section" element={<Admin />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/partner-login" element={<PartnerLoginPage />} />
        <Route
          path="/vendor-application-form"
          element={<VendorApplicationForm />}
        />
        <Route
          path="/corporate-order/:category"
          element={<CorporateOrderPage />}
        />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/auth-checkout" element={<CheckoutAuth />} />
        <Route
          path="/"
          element={
            <div>
              <LandingPage />
            </div>
          }
        />
        <Route
          path="/vendor-application-denied"
          element={<VendorApplicationDenied />}
        />
        <Route
          path="/vendor-application-pending"
          element={<VendorApplicationPending />}
        />
        <Route
          path="/seller-terms-conditions"
          element={<VendorTermsAndConditions />}
        />
        <Route path="/seller-auth" element={<AuthForm />} />
        <Route path="/seller-login" element={<SellerLoginPage />} />
        <Route
          path="/seller-dashboard"
          element={
            sellerAuth ? <SellerDashboard /> : <Navigate to="/seller-login" />
          }
        />
        <Route path="/ven-product-display" element={<VenProductDisplay />} />
        {/* <Route path="/account" element={<AccountPage />} />

        {/* 
        
        <Route path="/account" element={<AccountPage />} />
        <Route path="/signin" element={<PartnerLoginPage />} /> */}
      </Routes>
      {mobileActive === true && !isLandingPage && <AppBar />}
      {!isLandingPage && !hasAdminParams && !isAdminPage && <Footer />}
    </>
  );
}

export default App;
