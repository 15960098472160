import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SubList from "../navigationComponents/categoryComponents/SubList.tsx";

interface Props {
  styles: Record<string, string>;
  option: string;
  optionList: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const CategoryList = ({
  styles,
  option,
  optionList,
  isOpen,
  setIsOpen,
}: Props) => {
  const [toggled, setToggled] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
  const [menuData, setMenuData] = useState<any>(null);

  const currentScrollPos: number = window.scrollY;

  if (currentScrollPos < 350 && toggled === true) {
    setToggled(false);
  }

  const handleToggle = () => {
    toggled ? setToggled(false) : setToggled(true);
  };

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const dispatch = useDispatch();

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.list_header_container}
      >
        <h5>{optionList.header}</h5>
      </div>
      {showMenu && (
        <ul
          className={`${setShowMenu ? styles.show : styles.hidden}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <SubList
            styles={styles}
            optionList={optionList}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ul>
      )}
    </>
  );
};

export default CategoryList;
