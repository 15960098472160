import { create } from "zustand";

const useAuthStore = create((set) => ({
  sellerAuth: false,
  authedEmail: "",
  setSellerAuth: (status) => set({ sellerAuth: status }),
  setAuthedEmail: (email) => set({ authedEmail: email }),
}));

export default useAuthStore;
