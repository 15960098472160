import React from "react";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";
import styles from "../styles/partials/shipPoliciesStyles/shipPoliciesStyles.module.css";

export default function ShipPolicies() {
  return (
    <div className={styles.ship_policies_container}>
      <Link to="dev">
        <Logo />
      </Link>
      <Carousel1 />
      <h1>Shipping Policies</h1>
      <div>
        <h2>Thank you for choosing XGIFTSS.COM!</h2>
        <p>
          We want to make your gift shopping experience as enjoyable and
          straightforward as possible. Please take a moment to review our
          shipping policy.
        </p>

        <h3>1. Shipping Methods and Options</h3>
        <p>
          We offer the following shipping methods:
          <ul>
            <li>Standard Shipping</li>
            <li>Expedited Shipping</li>
            <li>Express Shipping</li>
          </ul>
          Shipping options and estimated delivery times may vary based on your
          location and product availability.
        </p>

        <h3>2. Shipping Costs</h3>
        <p>
          Shipping costs are calculated based on the weight of your order and
          your shipping destination. The total shipping cost will be displayed
          at checkout before you complete your purchase. We may offer free
          shipping promotions during specified periods.
        </p>

        <h3>3. Order Processing Time</h3>
        <p>
          Orders are typically processed within one to two business days of
          receiving payment. Please note that this processing time does not
          include weekends or holidays.
        </p>

        <h3>4. Tracking Your Order</h3>
        <p>
          Once your order has been shipped, you will receive a shipping
          confirmation email containing a tracking number. You can use this
          number to track the status of your shipment on the carrier&apos;s
          site.
        </p>

        <h3>5. International Shipping</h3>
        <p>
          Yes, we offer international shipping on all gifts except Alcohol.
          International shipping costs and delivery times vary based on the
          destination. Customers are responsible for any customs duties or taxes
          imposed by their respective countries.
        </p>
        <h3>6. Address Accuracy</h3>
        <p>
          Please ensure that your shipping address is accurate and complete. We
          are not responsible for orders shipped to incorrectly provided
          addresses. If you need to make changes after placing an order, contact
          us at <a href="mailto:support@xgiftss.com">support@xgiftss.com</a>{" "}
          immediately. Once an order is shipped, address changes are not
          possible.
        </p>

        <h3>7. Lost or Damaged Items</h3>
        <p>
          We take great care in packing your orders, but if an item is lost or
          damaged during transit, please contact our customer support team{" "}
          <a href="mailto:support@xgiftss.com">support@xgiftss.com</a> promptly.
          Provide order details and any relevant documentation, such as photos
          of damaged items, for timely assistance.
        </p>

        <h3>8. Shipping Restrictions</h3>
        <p>
          Certain products may be subject to shipping restrictions based on
          legal regulations or product nature. Please review our product
          listings and shipping policy for any applicable restrictions.
        </p>

        <h3>9. Contact Information</h3>
        <p>
          For any shipping-related inquiries or assistance, please contact our
          customer support team at{" "}
          <a href="mailto:support@xgiftss.com">support@xgiftss.com</a>.
        </p>

        <h3>10. LIQUOR, WINE & BEER SHIPPING</h3>
        <p>
          We cannot ship liquor, wine or beer outside of the United States and
          Canada. All gift baskets containing liquor, wine or beer require a
          signature of an adult over the age of 21 for delivery. Please keep
          this in mind when choosing where the gift basket will be delivered and
          calculating the delivery date. Our shipping carriers will make three
          attempts to deliver the gift before they return it to our supplier.
        </p>

        <h3>11. Policy Updates</h3>
        <p>
          We reserve the right to update our shipping policy at any time. Any
          changes will be posted on our website, and the effective date will be
          updated accordingly.
        </p>

        <p>Thank you for choosing xgiftss.com. We appreciate your business!</p>
      </div>
      <Carousel1 />
    </div>
  );
}
