import React from "react";
import { Typography, Container, Paper, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function DeniedApplication() {
  const navigate = useNavigate();

  const handleRetry = () => {
    // Navigate to the retry page or perform an action to retry
    // This is just a placeholder. Replace with actual retry logic if needed
    navigate("/dev");
  };

  return (
    <Container
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "400px",
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Application Denied
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
          We regret to inform you that your application has been denied. We
          understand this may be disappointing news. Please do not be
          discouraged. We invite you to re-apply in the next three days. Our
          team is here to support you through your next application.
        </Typography>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={handleRetry}
          sx={{ mt: 1 }}
        >
          Understand
        </Button>
      </Paper>
    </Container>
  );
}
