import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import axios from "axios";
import styles from "../../styles/partials/cartStyles/checkoutForm.module.css";
import { Carousel1 } from "../../components/informationComponents/Banner.tsx";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Box, Button } from "@mui/material";
import Stepper from "../cartComponents/Stepper.tsx";
import ShippingAddressComponent from "../cartComponents/ShippingForm.tsx";
import PersonalizationComponent from "../cartComponents/Personalization.tsx";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_LIVE_KEY}`
);

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export default function CheckoutForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems } = useSelector((store) => store.cart);
  const [clientSecret, setClientSecret] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [stripeLoading, setStripeLoading] = useState(true);

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    const createCheckoutSession = async () => {
      try {
        const response = await baseApi.post("/checkout", {
          items: cartItems,
        });
        const data = await response.data;
        setClientSecret(data.clientSecret);
        setStripeLoading(false);
      } catch (error) {
        console.error(error.message);
      } finally {
        // Set stripeLoading to false when Stripe is done loading
        setStripeLoading(false);
      }
    };

    createCheckoutSession();
  }, []);

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBackStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const options = { clientSecret };

  return (
    <div id="checkout" className={styles.checkout_form}>
      <Link className={styles.logo_a} to="/dev">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.banner_container}>
        <Carousel1 />
      </div>

      <Stepper activeStep={activeStep} />

      {/* Render additional components based on the active step */}
      {activeStep === 0 && (
        <ShippingAddressComponent
          onNext={handleNextStep}
          onBack={() => navigate("/cart")}
        />
      )}
      {activeStep === 1 && (
        <PersonalizationComponent
          onNext={handleNextStep}
          onBack={handleBackStep}
        />
      )}

      {/* Always render the EmbeddedCheckoutProvider */}
      <EmbeddedCheckoutProvider
        key={clientSecret}
        stripe={stripePromise}
        options={options}
      >
        {/* Render EmbeddedCheckout only when activeStep is 2 and Stripe is not loading */}
        {activeStep === 2 && !stripeLoading && (
          <>
            <EmbeddedCheckout />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "2em",
                gap: "2em",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Back button is visible when not on the first step */}
              <Box
                sx={{
                  width: "auto",
                  display: "flex",
                  gap: "8px",
                }}
              >
                {activeStep > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBackStep}
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Carousel1 />
            </Box>
          </>
        )}

        {/* Show loading indicator while Stripe is loading */}
        {activeStep === 2 && stripeLoading && (
          <Box
            sx={{ display: "flex", width: "100vw", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        )}
      </EmbeddedCheckoutProvider>

      {/* {activeStep < 3 && (
        <div className={styles.banner_container}>
          <Carousel1 />
        </div>
      )} */}

      {activeStep === 3 && (
        <div className={styles.banner_container}>
          {/* Additional content after completing the checkout process */}
        </div>
      )}
    </div>
  );
}
