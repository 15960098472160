import axios from "axios";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com", // Proxy server URL
});

export default async function fetchAllUsers(userEmail) {
  try {
    const response = await baseApi.post("/application", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userEmail: userEmail,
      }),
    });
    console.log(response);
    return response;
  } catch (error) {
    return error;
  }
}
