import React from "react";
import { Link } from "react-router-dom";

const GAGB = () => {
  return (
    <>
      <h2>SHIPPING & RETURNS</h2>

      {/* <p>
        Orders placed Monday through Friday before 3:00 pm EST will ship out the
        day the order is placed. Orders placed after 3:00 pm EST Monday through
        Friday will ship out the following business day. Orders placed on
        Saturday and Sunday will ship on Monday.
      </p> */}

      {/* <h3>SHIPPING OPTIONS</h3>

      <p>
        GreatArrivals will make every effort to meet your delivery needs and
        budget! For Free Shipping, we ship using the US Postal Service, UPS &
        FedEx, which take between 1-5 transit days Monday through Saturday. They
        do not deliver on Sunday.
      </p>

      <p>
        We also ship using several expedited methods including Overnight, 2 Day
        Expedited, 3 Day Expedited, Saturday delivery. We use FedEx/UPS to ship
        these packages.
      </p>

      <h4>FREE SHIPPING:</h4>

      <p>We offer Free Standard Shipping in the continental United States!</p> */}

      {/* <table>
        <thead>
          <tr>
            <th>Shipping Method</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Free Shipping</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>3 Day Expedited</td>
            <td>$19.99</td>
          </tr>
          <tr>
            <td>2 Day Expedited</td>
            <td>$24.99</td>
          </tr>
          <tr>
            <td>Overnight</td>
            <td>$34.99</td>
          </tr>
          <tr>
            <td>Saturday Delivery</td>
            <td>$44.99</td>
          </tr>
        </tbody>
      </table> */}

      <p>
        If you have any questions, please email us at{" "}
        <a href="mailto:support@xgiftss.com">support@xgiftss.com</a> and a
        Customer Service Representative will be happy to help you.
      </p>

      <h4>Future Delivery:</h4>

      <p>
        You can order now and have it shipped later! During checkout, you will
        be asked for your &apos;Preferred Delivery Date&apos;; just enter the
        date you would like your order to be delivered.
      </p>

      <h4>PO Boxes:</h4>

      <p>We do not ship to PO Boxes.</p>

      <h4>International Shipping:</h4>

      <p>
        We ship internationally, however, we cannot ship to Mexico or any
        countries in Africa. In addition, any gift baskets containing wine,
        fruit & cheesecakes cannot be shipped outside the United States. We do
        not ship internationally to PO Boxes.
      </p>

      <h4>APO/FPO:</h4>

      <p>We ship to APO or FPO addresses using the US Postal Service only.</p>

      <h3>WINE & BEER SHIPPING</h3>

      <p>We cannot ship wine or beer outside of the United States.</p>

      <p>
        All gift baskets containing wine or beer require a signature of an adult
        over the age of 21 for delivery. Please keep this in mind when choosing
        where the gift basket will be delivered and calculating the delivery
        date. Our shipping carriers will make three attempts to deliver the gift
        before they return it to GreatArrivals.
      </p>

      <p>
        GreatArrivals will not be responsible for gift baskets where an adult is
        not available to sign. We advise that you let the recipient know a gift
        is on its way.
      </p>

      <p>
        No refund will be issued as a result of your gift basket containing wine
        or beer being returned to us due to an adult over 21 not being available
        to sign for the delivery. However, if the gift basket is returned we
        will be happy to reship it to another location for only the cost of
        shipping and handling.
      </p>

      <h3>CHOCOLATE IN WARM WEATHER</h3>

      <p>
        During warm weather months (typically May-September), the chocolates
        contained in gift baskets may be substituted to avoid damage due to
        melting. Items containing chocolate must be shipped Expedited (Overnight
        or 2 Day Expedited) to avoid melting. If you choose a method other than
        Overnight or 2 Day Expedited, GreatArrivals will not be held responsible
        for damage when the melting occurs. If your gift recipient is not
        available at the time of delivery, we cannot be responsible for damage
        that occurs to those gifts having their delivery delayed or items
        delivered and left unattended in warm weather.
      </p>

      <h3>HOSPITAL DELIVERY</h3>

      <p>
        If you are shipping to a hospital, please be aware that often gifts are
        shipped to the receiving department and then need to be taken by
        hospital staff to the patient. We are not responsible for gift baskets
        becoming lost or misplaced once it is delivered to the hospital, failure
        of the hospital staff to deliver the gift to the patient&apos;s room, or
        arriving after a patient has been released.
      </p>

      <h3>RETURNS</h3>

      <p>
        Please contact Customer Service at{" "}
        <a href="mailto:support@xgiftss.com">support@xgiftss.com</a> if you wish
        to return your gift basket. All gift baskets should be returned within
        14 days of delivery in their original packaging. All other claims
        (damage, not delivered on time, etc.) must be made within 45 days.
      </p>
    </>
  );
};

const GGB = () => {
  return (
    <>
      <h2>STORE POLICIES/FAQS</h2>

      <p>
        <strong>What if we get an incorrect or incomplete address?</strong>
        <br />
        If we ship a gift basket to an incorrect address, then it will be
        returned to us. Unfortunately, FedEx charges us a flat $15 fee to return
        the package, which we must, in turn, charge you. Once a package with an
        incorrect or incomplete address has left the building, there is little
        we can do. On occasion, we can reroute a package to the proper address
        for a fee of $15, but this is not always successful. Either way, the
        package will be returned to us and must be sent back out for delivery.
        FedEx does not always call us to tell us that there is a problem
        delivering the package, and we often do not find out there is a problem
        until the package is returned. In these rare instances, we ask for your
        patience and promise in return to do everything in our power to make
        sure your gift basket arrives in perfect condition and as soon as
        humanly possible.
      </p>

      {/* <p>
        <strong>Are your delivery times guaranteed?</strong>
        <br />
        Yes! Shipments are guaranteed for Ground, 3 Day, 2 Day, Overnight, and
        Saturday. If our delivery partner misses the delivery, then we will
        refund the shipping on your gift. Please know that if the recipient is
        not home and was unable to deliver the package, this does not qualify as
        missing the delivery. Also, circumstances beyond our control, such as
        severe weather, natural disasters, and so forth, void the delivery
        guarantee. We run over 99% on time, so trouble with shipping is a very
        infrequent event. *Please note, during the months of November &
        December, delivery times cannot be guaranteed due to the increase in
        carrier volume.
      </p> */}
      <p>
        <strong>What if we get an incorrect or incomplete address?</strong>
        <br />
        If we ship a gift basket to an incorrect address, then it will be
        returned to us. Unfortunately, FedEx charges us a flat $15 fee to return
        the package, which we must, in turn, charge you. Once a package with an
        incorrect or incomplete address has left the building, there is little
        we can do. On occasion, we can reroute a package to the proper address
        for a fee of $15, but this is not always successful. Either way, the
        package will be returned to us and must be sent back out for delivery.
        FedEx does not always call us to tell us that there is a problem
        delivering the package, and we often do not find out there is a problem
        until the package is returned. In these rare instances, we ask for your
        patience and promise in return to do everything in our power to make
        sure your gift basket arrives in perfect condition and as soon as
        humanly possible.
      </p>

      <p>
        <strong>Can I send a package internationally?</strong>
        <br />
        Yes! Visit our International Gifts page to see the great options
        available to ship outside of the United States.
      </p>

      <p>
        <strong>How do you pack your gift baskets?</strong>
        <br />
        We use state-of-the-art instant foam packaging. Your gift will NEVER
        arrive with annoying popcorn and will be out of the box in seconds. The
        foam is perfectly formed to the contents of your gift and will require
        zero cleanup or hassle. Look for our competitors to use popcorn, paper,
        bubble wrap, and other inferior packaging materials that while they
        protect the gift in transit, severely hinder the presentation and
        diminish the enjoyment of receiving the gift.
      </p>

      <p>
        <strong>Summer Shipping</strong>
        <br />
        During the summer months, it becomes necessary to ship chocolate gift
        baskets with ice packs to ensure they arrive in nothing less than
        pristine condition. They must also be shipped via FedEx Express and
        cannot be shipped over the weekend. We do this to make sure that your
        gift basket is perfect when it arrives.
      </p>

      <p>
        <strong>Substitution Policy</strong>
        <br />
        For a variety of reasons, we will occasionally run out of a specific
        item. While this does not occur often, we reserve the right to
        substitute the unavailable item for a different one of equal or greater
        value.
      </p>

      <p>
        <strong>Return Policy</strong>
        <br />
        Please view our return policy{" "}
        <a href="your_return_policy_link_here">here</a>.
      </p>

      <h3>Return Policy</h3>
      <p>
        Please view our return policy <Link to="/return-policy">here</Link>.
      </p>

      <h3>Return Policy Details:</h3>

      <h4>Return Policy for Cancelled Orders</h4>
      <p>
        An order cannot be cancelled once it has been shipped. If you decide to
        cancel after shipment, our standard return policy applies.
      </p>

      <h4>Return Policy for Free Shipping Items</h4>
      <p>
        For items that shipped for free, our shipping costs will be deducted
        from your store credit.
      </p>

      <h4>Return Policy for Perishable Items</h4>
      <p>
        If a perishable item is returned to us marked as “undeliverable,” we
        cannot offer a store credit. Unfortunately, unlike items that have a
        longer shelf life, items that are perishable are unable to be resold
        after they are sent back to us. When this situation occurs, we do offer
        customers a 20% discount on a replacement order.
      </p>

      <h4>Return Policy for Strawberry/Chocolate Dipped Products</h4>
      <p>
        If you are not completely satisfied with your purchase, give us a call
        and we will happily replace your order free of charge. Please note that
        we cannot be responsible for delivery address errors that cause delayed
        arrival/spoilage or incorrect handling/storage of the product after
        delivery.
      </p>

      <h4>Return Policy for Damaged Products</h4>
      <p>
        If a product arrives damaged or with items missing, please notify us
        within 15 days. We would be happy to send you a replacement as soon as
        possible. Please keep in mind that missing items may have been
        substituted.
      </p>

      <h4>Substitution Policy</h4>
      <p>
        For a variety of reasons, we will occasionally run out of a specific
        item. While this situation does not occur often, we reserve the right to
        substitute an item of equal or greater value in our product.
      </p>

      <p>
        To return an item or order, please contact a member of our Customer
        Service Team at 888-208-3098.
      </p>
    </>
  );
};

const CGB = () => {
  return (
    <>
      <p>
        <strong>Shipping</strong>
        <br />
        Transit times above are BUSINESS days and do not include the day of
        order (see our Order Cut Off Times below to calculate your actual day of
        order). Our courier does not offer deliveries on weekends or holidays.
        Our courier does not accept specific time deliveries. ie. After 2pm.
        PLEASE NOTE: Remote areas may take longer than stated. GUARANTEED
        shipping is not available to more remote areas, RR, or PO box addresses.
      </p>

      <p>
        <strong>FREE SHIPPING:</strong> We offer free FedEx ground shipping on
        certain gift baskets. Offer is available for gift baskets delivered
        within Canada with a retail price of over $100 each. Shipping charges
        are per item. REMOTE addresses may incur an additional fee. We will
        contact you, before proceeding, if this affects your package.
      </p>

      <p>
        <strong>Order Cut Off Times</strong>
        <br />
        We will ship your gifts, including gift baskets, candy grams, cookie
        grams, balloons, and fudge, placed business days, Monday to Friday,
        before 12pm EST, on the same day you place the order. If you place your
        order after 12pm EST, on a weekend or holiday, your gift will be shipped
        out the next business day. (see transit times below). Order date is the
        date your order is processed, which may be different than the date of
        your actual order. eg. You place your order Monday, a business day, by
        12pm EST, your order date is Monday and your gift ships Monday. You
        place your order Monday, after 12pm EST, your order date is Tuesday and
        your gift ships Tuesday. In regards to transit time, your order/ship
        date is Day ZERO.
      </p>

      <p>
        We will ship your fresh baked cookie (in a tin) and giant cookie orders
        out the next business day, if your order is placed business days Monday
        to Friday before 8pm EST. If you place your order after 8pm EST, on a
        weekend, or holiday, we will ship your order out the second business
        day. (see transit times below). Order date is the date your ordered is
        processed which may be different than the date of your actual order. eg.
        You place your order Monday, a business day, by 8pm EST, your order date
        is Tuesday and your gift ships Tuesday. You place your order Monday,
        after 8pm EST, your order date is Wednesday and your gift ships
        Wednesday. In regards to transit time, your order/ship date is Day ZERO.
      </p>

      <p>
        We will ship your fresh popped popcorn as follows:
        <br />
        Orders placed by 12pm EST Monday-Friday are processed and shipped the
        next business day. Orders placed after 12pm, on the weekend or a
        holiday, will be processed and shipped the day after the next business
        day. eg. You place your order by 12pm EST Monday, a business day, your
        order date is Tuesday and your gift ships Tuesday. Your order is placed
        after 12pm EST Monday, a business day, your order date is Wednesday and
        your gift ships Wednesday. In regards to transit time, your order/ship
        date is Day ZERO.
      </p>

      <p>
        Shipping charges are per gift regardless if going to the same or
        different addresses as each gift is shipped in its own specially
        designed box. If address errors are made during the order process, we
        will make the necessary corrections on your behalf and charge your
        credit card accordingly.
      </p>

      <p>
        <strong>Delivery-FEDEX</strong>
        <br />A majority of our shipping is done via FEDEX. Deliveries are made
        between 9 and 6 EST Monday to Friday. FEDEX does not allow time specific
        deliveries for any shipments (eg. by 12pm). FEDEX does not phone to
        arrange deliveries for any shipments. Delivery times (eg. 1 day) are
        estimates and are not guaranteed unless Overnight or 3 day shipping is
        chosen. Overnight or 3 day service is not guaranteed between December 19
        and January 6. Shipment times reflect business days. Shipping to remote
        locations in Canada or the USA may require more time and an increase in
        shipping costs. We will not be held responsible for delays due to
        weather or other acts of God.
      </p>

      <p>
        The Alcohol and Gaming Commission of Ontario (AGCO) has issued Boodles
        of Baskets a license to deliver beer, liquor, wine, and champagne. All
        of our drivers are licensed to deliver alcohol. Only persons of legal
        drinking age can order alcohol from our website and receive delivery of
        baskets containing alcohol. Alcohol purchased should be for the personal
        consumption of the gift recipient and not for resale. If a gift
        recipient looks less than 25 years of age, we will require proof of age.
        Boodles of Baskets will comply with the rules and regulations as set out
        by the AGCO relating to this Liquor Delivery Service License. All wine,
        champagne, beer, and liquor baskets sold by Boodles of Baskets include a
        $15 delivery charge. So, for example, if the total price paid by a
        customer for a gift basket containing alcohol before tax is $125, the
        price is $110 + $15 for deliver for a total of $125.00 plus taxes. The
        AGCO requires us to charge a minimum delivery charge on all liquor
        orders. We cannot ship Alcohol to the USA.
      </p>

      <p>
        <strong>Hospital/Retirement/Colleges/and the like</strong>
        <br />
        Boodles of Baskets will not be held responsible for gifts and baskets
        that have been delivered to shipping and receiving but not forwarded to
        the patient/student/recipient by the accepting facility. We will,
        however, do everything in our power to help you find the gift. Please
        give us a call should you have issues.
      </p>

      <p>
        <strong>Non Delivery/Re-Delivery</strong>
        <br />
        Deliveries to apartments and businesses may require a signature.
        Shipments that do not request a signature may be left by the door or in
        the lobby if no one is home. If unable to contact the recipient after
        two attempts, to an apartment or business, the gift will be returned to
        us or left at a designated pick up location. The courier charges us for
        all packages returned to us. This fee will be charged to you as soon as
        we are notified by the courier. We will inform you immediately for
        further delivery instructions. An extra delivery charge will apply. If
        we do not hear back from you within 2 weeks of our contact, your basket
        will be disposed of, and no refund will be given.
      </p>

      <p>
        <strong>Incorrect Address</strong>
        <br />
        If a basket is returned to us due to an incorrect address provided by
        you, we will contact you immediately for a correction, and the package
        will be re-shipped at an additional shipping fee. If an address change
        request is made or needed while the package is on route, a re-direct fee
        of $15.95 will apply. It is the customer’s responsibility to provide us
        with a complete and correct address (including buzzer #). Please check
        your addresses carefully! (Including a daytime phone number where the
        recipient can be reached is highly recommended.)
      </p>

      <p>
        We are not responsible if the recipient is not home. Deliveries to
        homes, where the courier has direct access to the front door, will be
        left on the porch if safe to do so. Deliveries to apartments, offices,
        and businesses may require a signature. Deliveries that do not require a
        signature may be left in the lobby. For all deliveries, Two delivery
        attempts will occur (not on the same day). A delivery notice will be
        left if possible.
      </p>

      <p>
        If a phone number is provided the courier may call the recipient to make
        arrangements. If delivery is unable to proceed, the gift will be
        returned to us. Our shipper will charge us a return fee for all returned
        gifts, which will be charged to you via the payment method used for the
        order. Delivery times to remote areas may take longer.
      </p>

      <p>
        If an incorrect address is given, or the package needs to be redirected
        for any reason (other than our error), there is a re-direct fee of
        $15.95 + tax.
      </p>

      <p>
        <strong>Delivery Tracking</strong>
        <br />
        As long as you provide us with a valid email address, you will receive
        an automated email from our shipper FEDEX when your gift has shipped, if
        there are any exceptions and when it has been successfully delivered.
      </p>
    </>
  );
};

export { GAGB, GGB, CGB };
