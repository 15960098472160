import React, { useState, useEffect } from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Products, { product } from "../../data/products.js";

type FuncProps = {
  setSku: (sku: string) => void;
};

type venProducts = {
  products: [];
};

export default function ProductSearchbar() {
  const navigate = useNavigate();
  const selectedCountry = localStorage.getItem("selectedCountry") || "usa";
  const [searchableProducts, setSearchableProducts] = useState([]);
  useEffect(() => {
    const validProducts = JSON.parse(
      localStorage.getItem("countrySelectedProducts")
    );

    setSearchableProducts(validProducts);
  }, [selectedCountry]);

  const options = searchableProducts.map(
    (product) => `${product.item} (${product.sku})`
  );

  const handleProductSelect = (event, selectedOption) => {
    event.preventDefault();

    if (selectedOption && selectedOption.inputValue) {
      const matchResult = selectedOption.inputValue.match(/\(([^)]+)\)/);

      if (matchResult) {
        const sku = matchResult[1];
        // Navigate to the item page
        navigate(`/item-page/${sku}`);
      }
    }
  };
  return (
    <Box sx={{ width: "auto" }}>
      <Autocomplete
        options={options}
        variant="standard"
        id="product_searchbar"
        getOptionLabel={(option) => option}
        disableCloseOnSelect={false}
        renderInput={(params) => (
          <TextField {...params} label="Name / Product ID" />
        )}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            const selectedOption = options.find(
              (option) => option === event.target.value
            );
            handleProductSelect(event, { inputValue: selectedOption });
          }
        }}
      />
    </Box>
  );
}

export function ProductDataSearch({ setSku }: FuncProps) {
  const selectedCountry = localStorage.getItem("selectedCountry") || "usa";
  const [searchableProducts, setSearchableProducts] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    const validProducts = JSON.parse(
      localStorage.getItem("countrySelectedProducts")
    );

    setSearchableProducts(validProducts);
  }, [selectedCountry]);

  const options = searchableProducts.map((product) => `${product?.sku}`);

  console.log(selectedItem); // Log the selected item

  return (
    <Box sx={{ width: "auto" }}>
      <Autocomplete
        options={options}
        variant="standard"
        id="product_searchbar"
        getOptionLabel={(option) => option}
        disableCloseOnSelect={false}
        onInputChange={(event, value) => {
          setSelectedItem(value);
          setSku(value);
        }}
        renderInput={(params) => <TextField {...params} label="Product ID" />}
      />
    </Box>
  );
}
