import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "@mui/material";

interface Props {
  productId: string;
  product: object;
}

export default function InfoAccordion({ product, productId }: Props) {
  console.log(product, productId);
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Description</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{product?.description}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Measurments</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{product?.measurments}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Weight</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {product?.weight ? product?.weight : "Information N/A"}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>contents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {product?.contents ? product?.contents : "Information N/A"}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Shipping & Return Policies </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ cursor: "pointer" }}>
          <Link href={`/item-policies/${product?.vendor}`}>
            Shipping & Return Policies
          </Link>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
