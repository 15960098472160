import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  country: "usa", // Initial country value
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry(state, action) {
      localStorage.setItem("selectedCountry", action.payload);
      const storedSelectedCountry = localStorage.getItem("selectedCountry");
      state.country = storedSelectedCountry;
    },
  },
});

export const { setCountry } = countrySlice.actions;
export default countrySlice.reducer;
