import React from "react";
import { Button, Container, Typography, Paper, Grid, Box } from "@mui/material";
import useAuthStore from "../../features/zustandStore/authStore";
import { redirect, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import AdminComponent from "../adminComponents/venAdminComponents/AdminComponent.tsx";
import useAdminStore from "../../features/zustandStore/sellerAdminStore.js";
import SideBar from "../../components/adminComponents/venAdminComponents/SideBar.tsx";

const SellerDashboard = () => {
  const { setSellerAuth } = useAuthStore();
  const { setComponent } = useAdminStore();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Call the logout function from Zustand store
    setSellerAuth(false);
    navigate("/partner-login");
  };

  const handleAdminComponent = (comp) => setComponent(comp);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        minHeight: "80vh",
        marginTop: 20,
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        maxWidth: "100vw",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          minWidth: "100vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          minHeight: "80vh",
        }}
      >
        <SideBar
          handleAdminComponent={handleAdminComponent}
          handleLogout={handleLogout}
        />

        <AdminComponent />
      </Paper>
    </Container>
  );
};

export default SellerDashboard;
