import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearCart } from "../features/cart/cartSlice.js";
import styles from "../styles/partials/cartStyles/cartStyles.module.css";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import { changeQuantity, setTotal } from "../features/cart/cartSlice.js";
import { axios } from "axios";
import { product } from "../data/products.js";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

export default function CartPage() {
  const dispatch = useDispatch();
  const { cartAmount, total, cartItems } = useSelector((store) => store.cart);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [shippingAvailable, setShippingAvailable] = useState(Boolean || String);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [shippingPrice, setShippingPrice] = useState(Number);
  const selectedCountry = localStorage.getItem("selectedCountry");
  const [error, setError] = useState("");
  //dec 3 for state
  const [selectedMethod, setSelectedMethod] = useState({});
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const handleCheckout = () => {
    // Check user authentication status
    if (isAuthenticated) {
      // User is authenticated, proceed with checkout
      console.log("User is authenticated. Proceeding with checkout...");

      // Add your logic for handling the checkout process here
    } else {
      // User is not authenticated, redirect to sign-in or continue as guest
      console.log(
        "User is not authenticated. Redirecting to sign-in or continue as guest..."
      );
      loginWithRedirect();
      // or redirect to the guest checkout page
      // history.push("/guest"); // Make sure to import useHistory from react-router-dom
    }
  };

  const handleAddQuantity = (sku) => {
    const item = cartItems.find((item) => item.sku === sku);
    if (item) {
      dispatch(changeQuantity({ sku, quantity: item.quantity + 1 }));
    }
  };

  const handleSubtractQuantity = (sku) => {
    const item = cartItems.find((item) => item.sku === sku);
    if (item && item.quantity >= 1) {
      dispatch(changeQuantity({ sku, quantity: item.quantity - 1 }));
      setSelectedMethod((prev) => {
        const updatedMethod = { ...prev };
        delete updatedMethod[sku]; // Remove the shipping method for the removed item
        return updatedMethod;
      });
    }
  };

  const handleShippingMethodChange = (event, sku) => {
    const selectedMethodPrice = parseFloat(event.target.value).toFixed(2);

    setShippingMethods((prev) => ({
      ...prev,
      [sku]: selectedMethodPrice,
    }));

    //go into cartItems and spread every matching item into a new object
    //and put a new property that holds the shipping price value.
    const updatedCartItems = cartItems.map((item) => {
      if (item.sku === sku) {
        return {
          ...item,
          selectedShippingMethod: selectedMethodPrice,
        };
      }
      return item;
    });

    // Check the validity of the selected method of updatedCartItems we made.
    const isValid = updatedCartItems.every((item) => {
      return (
        item?.shipsTo?.includes(selectedCountry) &&
        item.selectedShippingMethod !== undefined &&
        item.selectedShippingMethod !== "not selected"
      );
    });

    // Update the overall validity state
    setCheckoutDisabled(!isValid);

    // Update selectedMethod state
    setSelectedMethod((prev) => ({
      ...prev,
      [sku]: selectedMethodPrice,
    }));

    // Calculate total price based on selected shipping methods
    const selectedShippingPrices = Object.values(selectedMethod).filter(
      (price) => !isNaN(price)
    );

    const retailPrice = product.map((item) => {
      if (item.sku === sku) {
        return item.retail;
      }
    });

    const filteredRetailPrices = retailPrice.filter(
      (price) => price !== undefined
    );

    const shippingPrice = Number(selectedMethodPrice);

    dispatch(
      setTotal({
        sku,
        shippingPrice: isNaN(shippingPrice) ? 0 : shippingPrice,
        filteredRetailPrices,
      })
    );
  };

  const handleItemClick = (e, item) => {
    e.preventDefault();
    navigate(`/item-page/${item}`);
  };
  console.log(cartItems);

  return (
    <section className={styles.cart_page}>
      <div className={styles.cart_logo_container}>
        <Link to="/dev">
          <Logo className={styles.logo} />
        </Link>
      </div>

      <Carousel1 />

      <div className={styles.cart_header_container}>
        <h5>YOUR SHOPPING CART {`( ITEMS: ${cartAmount} )`}</h5>
      </div>

      <div className={styles.cart_content_container}>
        <div className={styles.cart_content}>
          {!cartAmount && <h1>Cart is empty</h1>}
          {cartItems.map((item, index) => {
            return (
              <div key={index} className={styles.item_content}>
                <div className={styles.item_image_content}>
                  <p>{item.item}</p>
                  <p>Product ID: {item.sku}</p>
                  <div
                    className={styles.item_image}
                    style={{ backgroundImage: `url("${item.imgLink}")` }}
                    onClick={(e) => handleItemClick(e, item.sku)}
                  ></div>
                </div>
                {item?.shipsTo?.includes(selectedCountry) ? (
                  <div>
                    <form action="">
                      <label htmlFor={`country-${item.sku}`}>
                        Select your shipping method:
                      </label>
                      <select
                        name={`country-${item.sku}`}
                        id={`country-${item.sku}`}
                        onChange={(e) => {
                          handleShippingMethodChange(e, item.sku);
                        }}
                      >
                        {item.shippingMethods[selectedCountry].map(
                          (method, i) => (
                            <option key={i} value={method.price}>
                              {`${method.name} - ${
                                isNaN(parseFloat(method.price).toFixed(2))
                                  ? "Free"
                                  : parseFloat(method.price).toFixed(2)
                              }`}
                            </option>
                          )
                        )}
                        <option selected disabled>
                          -- select shipping method --
                        </option>
                      </select>
                    </form>
                    <div className={styles.quantity_container}>
                      <button onClick={() => handleSubtractQuantity(item.sku)}>
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleAddQuantity(item.sku)}>
                        +
                      </button>
                    </div>
                    {item.price}
                  </div>
                ) : (
                  <div>
                    <span>Cannot be shipped to {selectedCountry}</span>
                    <div className={styles.quantity_container}>
                      <button onClick={() => handleSubtractQuantity(item.sku)}>
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button onClick={() => handleAddQuantity(item.sku)}>
                        +
                      </button>
                    </div>
                    {item.price}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className={styles.subtotal_container}>
          <p>{cartItems.productName}</p>{" "}
          <p>
            SUBTOTAL: $
            {parseFloat(total.toFixed(2)) + parseFloat(shippingPrice)}
          </p>
          {/* <button
            className={`btn ${styles.clear_cart_btn}`}
            onClick={() => dispatch(clearCart())}
          >
            clear cart
          </button> */}
          {Object.keys(selectedMethod).length === cartItems.length &&
          Object.values(selectedMethod).every(
            (method) => method !== "not selected"
          ) ? (
            <Button
              component={Link}
              to={`${isAuthenticated == true ? "/checkout" : "/auth-checkout"}`}
              disabled={cartAmount == 0 ? true : false}
            >
              CHECKOUT NOW
            </Button>
          ) : (
            <>
              <p className={styles.error}>
                {error
                  ? error
                  : cartAmount === 0
                  ? "add item to checkout"
                  : "select shipping method to checkout"}
              </p>

              <Button
                component={Link}
                to={`${
                  isAuthenticated == true ? "/checkout" : "/auth-checkout"
                }`}
                disabled={true}
              >
                CHECKOUT NOW
              </Button>
            </>
          )}
        </div>
      </div>
      <Carousel1 />
    </section>
  );
}
