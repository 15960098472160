import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/partials/navigation/navBar.module.css";
import EmailInput from "../inputs/EmailInput.tsx";
import { ReactComponent as LogoWhite } from "../../images/logo-white-text.svg";
import { ReactComponent as Cart } from "../../images/Interface/Cart.svg";
import { ReactComponent as Heart } from "../../images/Interface/Heart.svg";
import { ReactComponent as UserPlaceholder } from "../../images/User/User_Placeholder.svg";
import AncorTag from "../buttonsComponents/AncorTag.tsx";
import handleScroll from "../../functions/navbarFuntions/showNavbar.tsx";
import CategoryNav from "./CategoryNav.tsx";
import { ReactComponent as SearchIcon } from "../../images/Interface/Search.svg";
import { ReactComponent as ShoppingBag } from "../../images/icons/shopping-bag-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import AuthButton from "../buttonsComponents/AuthButton.tsx";
import { setProducts } from "../../features/productFilter/filterSlice.js";
import { setCountry } from "../../features/countrySlice/countrySlice";
import { Box, Menu, MenuItem, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ProductSearchbar from "../informationComponents/ProductSearchbar.tsx";
import CountrySelect from "../informationComponents/CountrySelect.tsx";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import IconButton from "@mui/material/IconButton";

export default function NavBar() {
  const [showNavbar, setShowNavbar] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 529);
  const { cartAmount } = useSelector((state) => state.cart);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  // Open the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate("/dev");
  };

  // Close the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFirstAncorClick = () => {
    // Scroll back to the top of page
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/dev");
  };

  return (
    <>
      <nav className={`${styles.nav_container} ${styles.show_nav}`}>
        <div className={styles.nav_content}>
          <Box sx={{ height: "80px" }} onClick={handleFirstAncorClick}>
            <LogoWhite className={styles.logo_black} />
          </Box>
          {isOpen ? (
            <CategoryNav
              styles={styles}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : null}

          <AuthButton buttonText="sign in" styles={styles} />

          <div className={styles.shopping_bag_container}>
            <Link to="/cart" className={styles.shopping_bag_content}>
              {cartAmount ? cartAmount : 0}
              <ShoppingBag className={styles.shopping_bag} />
            </Link>
          </div>
        </div>
        {/* //bottom navbar */}
        <Box
          id={styles.buttom_nav_box}
          sx={{
            width: "100%",
            padding: "1em 1.5em",
            borderBottom: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "auto",
              display: "flex",
              gap: "2em",
              alignItems: "center",
            }}
          >
            <Button
              id="basic-button"
              aria-controls={anchorEl ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
              sx={{ color: "#07130c" }}
            >
              contact us
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <a id="mail-link" href="mailto:info@xgiftss.com">
                  info@xgiftss.com
                </a>
              </MenuItem>
              <MenuItem>
                <a id="mail-link" href="mailto:info@xgiftss.com">
                  support@xgiftss.com
                </a>
              </MenuItem>
              <MenuItem>
                <Link to="privacy-policies">Privacy Policies</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/terms-and-conditions">Terms & Conditions</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/about-us">About us</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/Shipping-policies">Shipping Policies</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/return-policies">Returns & Shipping</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/frequent-questions">Q&A</Link>
              </MenuItem>
            </Menu>
            <Link to="/calendar">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CalendarMonthIcon />
                <p>Calendar</p>
              </Box>
            </Link>
            <CountrySelect styles={styles} />
            <Link to="/partner-login">
              <AttachMoneyIcon />
              <Button
                sx={{
                  backgroundColor: "#07130c",
                  color: "#fcfaf7",
                  ":hover": {
                    backgroundColour: "#fcfaf7",
                    color: "#07130c",
                  },
                }}
              >
                xgiftss seller
              </Button>
            </Link>
          </Box>

          <Box sx={{ width: "300px" }}>
            <ProductSearchbar />
          </Box>
        </Box>
      </nav>
    </>
  );
}
