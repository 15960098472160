import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "../../styles/partials/shipPoliciesStyles/shipPoliciesStyles.module.css";
import { Carousel1 } from "../../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../../images/x-aboutus-banner.png";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { GAGB, GGB, CGB } from "./vendors.tsx";

export default function ItemPolicies() {
  const products = useSelector((state) => state.filter.filterProducts);
  const id = useParams();
  const vendor = id.id;

  return (
    <div className={styles.ship_policies_container}>
      <Link to="dev">
        <Logo />
      </Link>
      <Carousel1 />
      <h1>Shipping & Return Policies</h1>
      {vendor === "GAGB" ? (
        <GAGB />
      ) : vendor === "GGB" ? (
        <GGB />
      ) : vendor === "CGB" ? (
        <CGB />
      ) : null}
      <Carousel1 />
    </div>
  );
}
