import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Link,
  Container,
} from "@mui/material";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
});

export default function VendorApplicationForm() {
  const navigate = useNavigate();

  const [vendorData, setVendorData] = useState({
    companyName: "",
    contactName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    website: "",
    productCategory: "",
    businessType: "",
    taxId: "",
    agreeToTerms: false,
    sellsAlcohol: false,
    alcoholLicense: null,
  });

  const handleChange = (e) => {
    const { name, type, checked } = e.target;
    if (type === "file") {
      // Handle file input change
      setVendorData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      // Handle other input changes
      setVendorData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : e.target.value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // You would handle form submission here, including file upload
    try {
      // Prepare the form data for submission
      const formData = new FormData();
      for (const key in vendorData) {
        formData.append(key, vendorData[key]);
      }

      // Submit the form data to your `/application` endpoint
      const response = await baseApi.post("/application", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);

      navigate("/seller-auth");
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        marginTop: {
          xs: 15, // for mobile screens
          sm: 23, // for larger screens
        },
        marginBottom: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography component="h1" variant="h5">
        Vendor Application Form
      </Typography>
      <form onSubmit={handleSubmit}>
        {/* The rest of the input fields are similar to the ones below */}
        <TextField
          margin="normal"
          fullWidth
          required
          id="companyName"
          label="Company Name"
          name="companyName"
          autoComplete="company-name"
          value={vendorData.companyName}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={vendorData.email}
          onChange={handleChange}
        />
        {/* ... add other TextField components for each state property ... */}
        <TextField
          margin="normal"
          fullWidth
          id="city"
          label="City"
          name="city"
          value={vendorData.city}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="state"
          label="State"
          name="state"
          value={vendorData.state}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="zipCode"
          label="Zip Code"
          name="zipCode"
          value={vendorData.zipCode}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="country"
          label="Country"
          name="country"
          value={vendorData.country}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="website"
          label="Website"
          name="website"
          value={vendorData.website}
          onChange={handleChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              name="sellsAlcohol"
              checked={vendorData.sellsAlcohol}
              onChange={handleChange}
            />
          }
          label="Do you sell alcohol?"
        />
        {vendorData.sellsAlcohol && (
          <>
            <Typography variant="caption" display="block">
              Please upload your alcohol license
            </Typography>
            <TextField
              type="file"
              name="alcoholLicense"
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              id="agreeToTerms"
              name="agreeToTerms"
              color="primary"
              checked={vendorData.agreeToTerms}
              onChange={handleChange}
            />
          }
          label={
            <>
              I agree to the
              <Link
                href="/seller-terms-conditions"
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions
              </Link>
            </>
          }
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Submit Application
        </Button>
      </form>
    </Container>
  );
}
