import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Dashboard from "../components/adminComponents/Dashboard.jsx";
import Sidebar from "../components/navigationComponents/Sidebar.tsx";
import { useParams } from "react-router-dom";
import ProductSearchbar from "../components/informationComponents/ProductSearchbar.tsx";
import ProductAdminSection from "../components/adminComponents/ProductAdminSection.tsx";
import EmailList from "../components/adminComponents/EmailList.tsx";

export default function Admin() {
  const { section } = useParams();
  const [currentSection, setCurrentSection] = useState(section);

  useEffect(() => {
    setCurrentSection(section);
  }, [section]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
      }}
    >
      <Sidebar />
      {currentSection === "dashboard" && <Dashboard />}
      {currentSection === "user" && <h1>User</h1>}
      {currentSection === "product" && <ProductAdminSection />}
      {currentSection === "email-list" && <EmailList />}
      {currentSection === "CMS" && <h1>CMS</h1>}
      {currentSection == null || undefined ? <Dashboard /> : null}
    </Box>
  );
}
