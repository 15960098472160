import React from "react";
import styles from "../styles/partials/termsAndConditions/termsAndConditions.module.css";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";

export default function TermsAndConditionsPage() {
  return (
    <section className={styles.terms_conditions_page}>
      <Link to="/dev">
        <Logo className={styles.logo} />
      </Link>

      <div className={styles.terms_conditions_container}>
        <article className={styles.content}>
          <Carousel1 />
          <h1>Terms and Conditions</h1>
          <img src={aboutUsBanner} alt="about us" />
          <h2>1. Acceptance of Terms</h2>
          <p>
            These Terms and Conditions (&quot;Terms&quot;) constitute a legally
            binding agreement between you and (&quot;Xgiftss&quot;
            &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) governing the
            use of our website, (&quot;https://www.xgiftss.com/&quot; the
            &quot;Website&quot;). By accessing and using the Website, you agree
            to be bound by these Terms and all applicable laws and regulations.
          </p>

          <h2>2. Intellectual Property</h2>
          <p>
            The content on this Website, including but not limited to text,
            graphics, logos, images, and software, is the intellectual property
            of [Your Company Name] and is protected by copyright and other laws.
            You may not modify, reproduce, or distribute any content without our
            prior written consent.
          </p>

          <h2>3. User Accounts</h2>
          <p>
            You may need to create a user account to access certain features of
            the Website. You are responsible for maintaining the confidentiality
            of your account and password and for restricting access to your
            computer. You agree to accept responsibility for all activities that
            occur under your account.
          </p>

          <h2>4. Privacy Policy</h2>
          <p>
            Your use of this Website is also subject to our &quot;
            <a href="https://xgiftss.com/privacy-policy">Privacy Policy</a>{" "}
            &quot;, which outlines how we collect, use, and protect your
            personal information.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            In no event shall [Your Company Name], its affiliates, partners,
            employees, or agents be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection
            with your use of the Website.
          </p>

          <h2>6. Governing Law and Jurisdiction</h2>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of [Your Jurisdiction]. Any dispute arising under these
            Terms shall be subject to the exclusive jurisdiction of the courts
            in [Your Jurisdiction].
          </p>

          <h2>7. Changes to Terms</h2>
          <p>
            We reserve the right to modify or replace these Terms at any time.
            Changes will be effective immediately upon posting. Your continued
            use of the Website after the posting of any changes to the Terms
            constitutes acceptance of those changes.
          </p>

          <h2>8. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms and
            Conditions, please contact us at:
            <br />
            info@xgiftss.com
            {/* <br />
            [Your Contact Phone Number]
            <br />
            [Your Company Address] */}
          </p>
          <h3>9. LIQUOR, WINE & BEER SHIPPING</h3>
          <p>
            We cannot ship liquor, wine or beer outside of the United States and
            Canada. All gift baskets containing liquor, wine or beer require a
            signature of an adult over the age of 21 for delivery.{" "}
            <strong>
              Please be advised that a valid ID will be required upon delivery
              to verify age.
            </strong>{" "}
            Consider this when selecting the delivery address and ensure that an
            adult who can present a valid ID will be available to accept the
            order. Our shipping carriers will make three attempts to deliver the
            gift before it is returned to us.
          </p>
          <Carousel1 />
        </article>
      </div>
    </section>
  );
}
