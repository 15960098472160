import React, { useState } from "react";
import axios from "axios";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import { useNavigate } from "react-router-dom";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
});

function AuthForm() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [registrationStatus, setRegistrationStatus] = useState(null);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Make a request to your backend API for registration or login
      const response = await baseApi.post("/register", formData);
      console.log(response.data);
      setRegistrationStatus("success");
      navigate("/seller-login");
    } catch (error) {
      console.error("Error:", error.message);

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Display the specific error message to the user
        setRegistrationStatus(error.response.data.message);
      } else {
        // Display a generic error message
        setRegistrationStatus("error");
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  {
    registrationStatus === "success" && (
      <Typography variant="body2" color="success">
        Registration successful!
      </Typography>
    );
  }

  {
    registrationStatus === "Email is already registered" && (
      <Typography variant="body2" color="error">
        This email is already registered. Please use a different email.
      </Typography>
    );
  }

  {
    registrationStatus === "error" && (
      <Typography variant="body2" color="error">
        Error during registration. Please try again.
      </Typography>
    );
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 8,
        height: "80vh",
        justifyContent: "center",
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo />
        <Typography component="h1" variant="h5">
          Seller Registration
        </Typography>
        <TextField
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          id="password"
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          value={formData.password}
          onChange={handleChange}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
          Submit
        </Button>

        {registrationStatus === "success" && (
          <Typography variant="body2" color="success">
            Registration successful! Display success component here.
          </Typography>
        )}

        {registrationStatus === "error" && (
          <Typography variant="body2" color="error">
            Error during registration. Please try again.
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default AuthForm;
