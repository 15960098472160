import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import AuthButton from "../buttonsComponents/AuthButton.tsx";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setOrderDetails } from "../../features/order/orderSlice.js";
import isValidEmail from "../../functions/emailValidator.js";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/logo-white-text.svg";
import { Carousel1 } from "../../components/informationComponents/Banner.tsx";
import { useTheme } from "@mui/system";
const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export default function CheckoutAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [guestEmail, setGuestEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const theme = useTheme();
  // get cart data
  const { cartItems } = useSelector((state) => state.cart);

  const handleContinueAsGuest = async () => {
    // Validate email before making the request
    if (!isValidEmail(guestEmail)) {
      setEmailError("Invalid email address");
      return;
    }

    try {
      // Make a request to your server to generate a session token
      const response = await baseApi.post("/generateSessionToken", {
        guestEmail: guestEmail,
      });

      // Reset email error if it was previously set
      setEmailError("");

      // You can access the session token from the response
      const sessionToken = response.data.sessionToken;

      // Dispatch the session token to Redux (or handle it as needed)
      dispatch(setOrderDetails({ sessionToken }));
      navigate("/checkout");
      // Proceed with other actions, e.g., redirect to checkout page
      // You may want to use react-router or other navigation methods here
    } catch (error) {
      console.error("Error generating session token:", error.message);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "80vh",
        width: "100vw",
        display: "flex",
        padding: "2em 15em ",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fcfaf7",
        flexDirection: "column",
        gap: "1em",
        [theme.breakpoints.up("xl")]: {
          padding: "2em 40em",
        },
        [theme.breakpoints.down("sm")]: {
          padding: "2em 8px", // Adjust the values for smaller screens
        },
      }}
    >
      <Box
        sx={{
          marginTop: "150px",
          width: "auto",
          position: "relative",
          height: "100%",
        }}
      >
        <Link to="/dev">
          <Logo />
        </Link>
      </Box>
      <Carousel1 />
      <h3>Please</h3>
      <Box
        sx={{
          width: "auto",
          padding: "0.225em 4em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AuthButton buttonText="SIGN IN" />

        <AuthButton buttonText="SIGN UP" />
      </Box>
      <p>
        <b>or</b>
      </p>
      <h3>Guest Checkout</h3>
      <Box
        sx={{
          width: "auto",
          padding: "2em 2em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: "4px",
        }}
      >
        <TextField
          id="standard-basic"
          label="Email"
          variant="standard"
          value={guestEmail}
          onChange={(e) => setGuestEmail(e.target.value)}
          error={Boolean(emailError)}
          helperText={emailError}
        />
        <Button variant="contained" onClick={handleContinueAsGuest}>
          Checkout
        </Button>
      </Box>
      <Carousel1 />
    </Box>
  );
}
