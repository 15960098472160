import React from "react";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";
import styles from "../styles/partials/shipPoliciesStyles/shipPoliciesStyles.module.css";
import { Box } from "@mui/material";

export default function FrequentQuestions() {
  return (
    <div className={styles.ship_policies_container}>
      <Link to="dev">
        <Logo />
      </Link>
      <Carousel1 />
      <h1>FREQUENTLY ASKED QUESTIONS:</h1>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "2em" }}>
        <p>
          <strong>Q1:</strong> What shipping options do you offer?
          <br />
          <strong>A1:</strong> We offer several shipping options, including
          standard, expedited, and express shipping. The available options may
          vary depending on your location.
        </p>

        <p>
          <strong>Q2:</strong> How can I track my order?
          <br />
          <strong>A2:</strong> Once your order has been shipped, you will
          receive a tracking number via email. You can use this tracking number
          &apos;s website to monitor the progress of your shipment.
        </p>

        <p>
          <strong>Q3:</strong> What is the estimated delivery time for standard
          shipping?
          <br />
          <strong>A3:</strong> The estimated delivery time for standard shipping
          depends on your location. Generally, it ranges from 3 to 7 business
          days within the United States or Canada. International shipping may
          take longer.
        </p>

        <p>
          <strong>Q4:</strong> Do you offer international shipping?
          <br />
          <strong>A4:</strong> Yes, we do offer international shipping. Shipping
          costs and delivery times vary based on the destination. Please refer
          to our shipping policy for more details.
        </p>

        <p>
          <strong>Q5:</strong> Can I change my shipping address after placing an
          order?
          <br />
          <strong>A5:</strong> Once an order has been confirmed, we are unable
          to change the shipping address. Please double-check your shipping
          information before completing your purchase.
        </p>

        <p>
          <strong>Q6:</strong> What happens if my package is lost or damaged
          during shipping?
          <br />
          <strong>A6:</strong> We take responsibility for lost or damaged items
          during shipping. Please contact our customer support team at
          support@xgiftss.com as soon as possible, providing order details and
          any relevant documentation (such as photos of damaged items).
        </p>

        <p>
          <strong>Q7:</strong> Are there any shipping restrictions or
          limitations?
          <br />
          <strong>A7:</strong> Shipping restrictions may apply to certain
          products [liquor, wine & Beer] based on their nature or legal
          regulations. Please review our shipping policy for specific details on
          any restrictions that may affect your order.
        </p>

        <p>
          <strong>Q8:</strong> Do you offer free shipping?
          <br />
          <strong>A8:</strong> We may offer free shipping promotions from time
          to time. Please check our website or subscribe to our newsletter for
          the latest updates on promotions and discounts.
        </p>

        <p>
          <strong>Q9:</strong> Can I combine multiple orders to save on shipping
          costs?
          <br />
          <strong>A9:</strong> Unfortunately, we are unable to combine multiple
          orders once they have been placed. Each order will be shipped
          separately.
        </p>

        <p>
          <strong>Q10:</strong> How can I contact customer support regarding
          shipping inquiries?
          <br />
          <strong>A10:</strong> For any shipping-related inquiries or concerns,
          please reach out to our customer support team through the contact
          information provided on our website or use the customer support email
          support@xgiftss.com.
        </p>
      </Box>
      <Carousel1 />
    </div>
  );
}
