import React from "react";
import styles from "../styles/partials/termsAndConditions/termsAndConditions.module.css";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";

export default function TermsAndConditionsPage() {
  return (
    <section className={styles.terms_conditions_page}>
      <Link to="/dev">
        <Logo className={styles.logo} />
      </Link>

      <div className={styles.terms_conditions_container}>
        <article className={styles.content}>
          <Carousel1 />
          <h1>Seller Terms and Conditions</h1>
          <img src={aboutUsBanner} alt="About Us" />
          <h2>1. Acceptance of Seller Agreement</h2>
          <p>
            By utilizing the services and platform provided by Xgiftss
            (&#34;we,&#34; &#34;us,&#34; or &#34;our&#34;) on the website,
            (&#34;https://www.xgiftss.com/&#34; the &#34;Platform&#34;), you,
            the seller, agree to comply with and be legally bound by these
            Seller Terms and Conditions (&#34;Agreement&#34;). This Agreement
            stipulates the terms under which you may access and use our
            Platform. Your engagement with the Platform constitutes your
            acceptance of this Agreement and all applicable laws.
          </p>

          <h2>2. Ownership and Intellectual Property Rights</h2>
          <p>
            All materials, including but not limited to the text, design
            elements, graphics, and interactive features on the Platform, are
            owned by Xgiftss or our licensors and are protected under
            intellectual property laws. Unauthorized use, modification,
            reproduction, or distribution of the Platform&#39;s content is
            strictly prohibited without express written consent from Xgiftss.
          </p>

          <h2>3. Account Responsibility</h2>
          <p>
            To access certain functionalities of the Platform, you are required
            to create an account. You are solely responsible for safeguarding
            your account credentials and for all activities that occur under
            your account. You agree to notify us immediately should any
            unauthorized use of your account occur.
          </p>

          <h2>4. Privacy Commitment</h2>
          <p>
            Your interaction with the Platform is also governed by our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>, which delineates
            our practices regarding the collection, use, and protection of your
            personal information.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            Under no circumstances will Xgiftss, its affiliates, or their
            respective partners, employees, or agents be liable for any
            indirect, incidental, special, punitive, or consequential damages
            arising from your use of the Platform or any services provided
            herein.
          </p>

          <h2>6. Governing Law</h2>
          <p>
            The terms of this Agreement shall be governed by the laws of the
            jurisdiction in which Xgiftss operates. Any disputes arising from or
            in connection with this Agreement shall be adjudicated in the courts
            of said jurisdiction.
          </p>

          <h2>7. Amendment of Terms</h2>
          <p>
            Xgiftss reserves the right to amend this Agreement at any time. Any
            modifications will become effective immediately upon posting to the
            Platform. Continued use of the Platform following any such changes
            constitutes your acceptance of the new terms.
          </p>

          <h2>8. Seller Support</h2>
          <p>
            Should you have any inquiries or require assistance regarding this
            Agreement, please contact us at:
            <br />
            info@xgiftss.com
          </p>

          <h2>9. Alcohol Shipment Regulations</h2>
          <p>
            We do not engage in international shipping of liquor, wine, or beer.
            All orders containing alcoholic beverages require an adult signature
            upon delivery. We strongly advise providing a delivery address where
            an adult capable of presenting valid photo identification is
            available to accept the order.
          </p>

          <Carousel1 />
        </article>
      </div>
    </section>
  );
}
