import React, { useState, useEffect } from "react";
import { ProductDataSearch } from "../informationComponents/ProductSearchbar.tsx";
import styles from "../../styles/partials/adminStyles/admin.module.css";
import { Box } from "@mui/material";
import { product } from "../../data/products.js";
import handleProductSelect from "../../functions/handleProdctSelect.ts";
import { ProductAdminSkeleton } from "../../components/informationComponents/ProductCard.tsx";
import axios from "axios";

const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
});
export default function ProductAdminSection() {
  // pass setProductSku to ProductDataSearch component to set productSku
  const [productSku, setProductSku] = useState([]);
  const [venProductSku, setVenProductSku] = useState([]);

  // pass productData to ProductDataSearch component to set product after a productSku has bee selected
  const [productData, setProductData] = useState(product);
  const [venProductData, setVenProductData] = useState([]);

  useEffect(() => {
    // handleProductSelect will return the product data and save it to productData.
    const productData = handleProductSelect(productSku);

    // Define getVenProductData as an async function
    const getVenProductData = async () => {
      try {
        const response = await baseApi.get(`/get-ven-products`);
        setVenProductData(response.data);
        console.log(response.data);
        // this will log the data from the response
      } catch (error) {
        console.error("Failed to fetch ven products:", error);
      }
    };

    getVenProductData();

    // setProductData will update the productData state with the new product data.
    setProductData(productData);
  }, [productSku]);

  useEffect(() => {
    const skus = venProductData.map((item) => item.sku);
    setVenProductSku(skus);
    console.log(skus);
  }, [venProductData]);

  return (
    <div className={styles.admin_section}>
      <h3>
        <b> Product Options</b>
      </h3>
      <Box sx={{ width: 400 }}>
        <p>Search For a Product</p>
        <ProductDataSearch setSku={setProductSku} />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {Array.isArray(productData) && (
            <ProductAdminSkeleton key={product.sku} productData={productData} />
          )}
        </Box>
      </Box>
      <h3>
        <b>Ven Product Options</b>
      </h3>
      <Box sx={{ width: 400 }}>
        <p>Search For a Product</p>
        <ProductDataSearch setSku={setVenProductSku} />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {Array.isArray(venProductData) && (
            <ProductAdminSkeleton
              key={venProductData.sku}
              productData={venProductData}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
