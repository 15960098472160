// orderSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    orderDetails: {}, // You can structure this based on your needs
  },
  reducers: {
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
      console.log(state.orderDetails);
    },
  },
});

export const { setOrderDetails } = orderSlice.actions;

export const selectOrderDetails = (state) => state.order.orderDetails;

export default orderSlice.reducer;
