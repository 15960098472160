import React from "react";
import styles from "../styles/partials/productPage/productPage.module.css";
import { product } from "../data/products.js";
import ProductSection from "../components/productPageComponents/ProductSection.tsx";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";

export default function ProductPage() {
  return (
    <div className={styles.product_page_container}>
      <ProductSection styles={styles} product={product} />
    </div>
  );
}
