import React from "react";
import { TextField, Button, Box } from "@mui/material";

interface PersonalizationProps {
  onNext: () => void;
  onBack: () => void;
}

const PersonalizationComponent: React.FC<PersonalizationProps> = ({
  onNext,
  onBack,
}) => {
  const handleNextClick = () => {
    onNext();
  };

  const handleBackClick = () => {
    onBack();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0 2em",
        gap: "1em",
      }}
    >
      <h2>Personalization</h2>
      <TextField label="Personalized Message" fullWidth />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "2em",
          gap: "2em",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleBackClick}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalizationComponent;
