import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { store } from "./store/store.js";

export const Auth0ProviderWithNavigate = () => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_OKTA_REDIRECT_URI;

  const onRedirectCallback = (appState) => {
    navigate(window.location.replace("https://xgiftss.com/dev"));
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: redirectUri,
        }}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  );
};
