import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals.js";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate.tsx";
import ScrollToTop from "./components/navigationComponents/ScrollToTop.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Auth0ProviderWithNavigate />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
