import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoBlue } from "../../images/logo-white-text.svg";
import { useSelector } from "react-redux";
import {
  setFilterProducts,
  setProducts,
} from "../../features/productFilter/filterSlice.js";
import { Carousel1 } from "../informationComponents/Banner.tsx";
import { useParams } from "react-router-dom";
import { PriceFilter } from "../inputs/PriceFilter.tsx";

interface Props {
  styles: Record<string, string>;
}

export default function HeaderSection({ styles }: Props) {
  const { category } = useParams();

  const selectedCategory = useSelector(
    (state) => state.category.selectedCategory
  );

  return (
    <div className={styles.header_container}>
      <div className={styles.header_content}>
        <Link to="/dev">
          <LogoBlue className={styles.logo} />
        </Link>

        <Carousel1 />

        {selectedCategory && <h1>{category}</h1>}
        <PriceFilter styles={styles} />
        {/*  <h5>find any type of gift for any type of occassion</h5> */}
      </div>
    </div>
  );
}
