import React, { useEffect, useState } from "react";
import ProductCard from "../informationComponents/ProductCard.tsx";
import { product } from "../../data/products.js";
import {
  HomeMainBanner,
  Banner,
  BirthdayBanner,
  ExploreBanner,
  TasteCanadaBanner,
  HomeMainBanner2,
  Carousel1,
} from "../informationComponents/Banner.tsx";
import HolidayBanner from "../homeComponents/HolidaySection.tsx";
import SendLoveBanner from "./GeekSection.tsx";
import EveryonSection from "./EveryoneSection.tsx";
import getRandomProducts from "../../functions/getRandomProducts.ts";
import { useSelector } from "react-redux/es/hooks/useSelector.js";
import { setProducts } from "../../features/productFilter/filterSlice.js";

interface Props {
  styles: Record<string, string>;
  banner?: "enable" | "disable";
  holidayBanner?: boolean;
  sendLoveBanner?: boolean;
  everyoneBanner?: boolean;
  homeMainBanner?: boolean;
}

// function BirthdaySection({ styles }: Props) {
//   const [randomProducts, setRandomProducts] = useState([]);

//   useEffect(() => {
//     setRandomProducts(getRandomProducts(product, 16, 1000));
//   }, []);

//   return (
//     <div className={styles.selling_container}>
//       <BirthdayBanner />
//       <div className={styles.best_selling_content}>
//         {randomProducts.map((item, index) => {
//           return (
//             <div className="product_card" key={`${product.sku}-${index}`}>
//               <ProductCard product={item} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

function OccasionSection({ styles }: Props) {
  const [randomProducts, setRandomProducts] = useState([]);
  const selectedCountry = useSelector((state) => state.country.country);

  const newProducts = useSelector((state) => state.filter.filterProducts);
  useEffect(() => {
    setRandomProducts(getRandomProducts(newProducts));
  }, [selectedCountry]);

  const getLimitedRandomProducts = (arr, limit) => {
    const shuffled = arr.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, limit);
  };

  return (
    <div className={styles.selling_container}>
      <Carousel1 />
      <div className={styles.best_selling_content}>
        {getLimitedRandomProducts(randomProducts, 96).map((item, index) => {
          const isCarouselItem = (index + 1) % 16 === 0;

          return (
            <React.Fragment key={`${item.sku}-${index}`}>
              <div className="product_card">
                <ProductCard product={item} />
              </div>
              {(index + 1) % 16 === 0 && <Carousel1 />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

// function ExploreSection({ styles }: Props) {
//   const [randomProducts, setRandomProducts] = useState([]);

//   useEffect(() => {
//     setRandomProducts(getRandomProducts(product, 16, 1000));
//   }, []);

//   return (
//     <div className={styles.selling_container}>
//       <ExploreBanner />
//       <div className={styles.best_selling_content}>
//         {randomProducts.map((item, index) => {
//           return (
//             <div className="product_card" key={`${product.sku}-${index}`}>
//               <ProductCard product={item} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// function TasteCanadaSection({ styles }: Props) {
//   const [randomProducts, setRandomProducts] = useState([]);

//   useEffect(() => {
//     setRandomProducts(getRandomProducts(product, 16, 1000));
//   }, []);

//   return (
//     <div className={styles.selling_container}>
//       <TasteCanadaBanner />
//       <div className={styles.best_selling_content}>
//         {randomProducts.map((item, index) => {
//           return (
//             <div className="product_card" key={`${product.sku}-${index}`}>
//               <ProductCard product={item} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// function HomeMainSection2({ styles }: Props) {
//   const [randomProducts, setRandomProducts] = useState([]);

//   useEffect(() => {
//     setRandomProducts(getRandomProducts(product, 16, 1000));
//   }, []);

//   return (
//     <div className={styles.selling_container}>
//       <HomeMainBanner2 />
//       <div className={styles.best_selling_content}>
//         {randomProducts.map((item, index) => {
//           return (
//             <div className="product_card" key={`${product.sku}-${index}`}>
//               <ProductCard product={item} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

// function ForHerSection({ styles }: Props) {
//   const [randomProducts, setRandomProducts] = useState([]);

//   useEffect(() => {
//     setRandomProducts(getRandomProducts(product, 16, 1000));
//   }, []);

//   return (
//     <div className={styles.selling_container}>
//       <Carousel1 />
//       <div className={styles.best_selling_content}>
//         {randomProducts.map((item, index) => {
//           return (
//             <div className="product_card" key={`${product.sku}-${index}`}>
//               <ProductCard product={item} />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

export {
  // BirthdaySection,
  OccasionSection,
  // ExploreSection,
  // TasteCanadaSection,
  // HomeMainSection2,
  // ForHerSection,
};
