import React from "react";
import { ReactComponent as Logo } from "../images/logo-white-text.svg";
import { Link } from "react-router-dom";
import { Carousel1 } from "../components/informationComponents/Banner.tsx";
import aboutUsBanner from "../images/x-aboutus-banner.png";
import styles from "../styles/partials/shipPoliciesStyles/shipPoliciesStyles.module.css";

export default function ReturnPolicies() {
  return (
    <div className={styles.ship_policies_container}>
      <Link to="dev">
        <Logo />
      </Link>
      <Carousel1 />
      <h1>SHIPPING & RETURNS</h1>

      <div>
        <p>
          Orders placed Monday through Friday before 3:00 pm will ship out the
          day the order is placed.
        </p>

        <p>
          Orders placed after 3:00 pm Monday through Friday will ship out the
          following business day.
        </p>

        <p>Orders placed on Saturday and Sunday will ship on Monday.</p>

        <h2>SHIPPING OPTIONS</h2>

        <p>
          XGIFTSS.COM will make every effort to meet your delivery needs and
          budget! For Free Shipping, we ship using the US Postal Service, UPS &
          FedEx, which takes between 1-5 transit days Monday through Saturday.
          They do not deliver on Sunday.
        </p>

        <h2>FREE SHIPPING:</h2>
        <p>
          We offer Free Standard Shipping in the continental United States and
          Canada.
        </p>

        <table>
          <thead>
            <tr>
              <th>Shipping Method</th>
              <th>Cost USD</th>
              <th>Cost CAN</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Free Shipping</td>
              <td>$0</td>
              <td>$0</td>
            </tr>
            <tr>
              <td>5 to 7 Day</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3 Day Expedited</td>
              <td>$19.99</td>
              <td>$ DAILY EXCHANGE</td>
            </tr>
            <tr>
              <td>2 Day Expedited</td>
              <td>$24.99</td>
              <td>$ DAILY EXCHANGE</td>
            </tr>
            <tr>
              <td>Overnight</td>
              <td>$34.99</td>
              <td>$ DAILY EXCHANGE</td>
            </tr>
            <tr>
              <td>Saturday Delivery</td>
              <td>$44.99</td>
              <td>$ DAILY EXCHANGE</td>
            </tr>
          </tbody>
        </table>

        <p>
          If you have any questions, please email us at{" "}
          <a href="mailto:support@xgiftss.com">support@xgiftss.com</a> and a
          Customer Service Representative will be happy to help you.
        </p>

        <h2>Future Delivery:</h2>
        <p>
          You can order now and have it shipped later! During checkout, you will
          be asked for your &quot;Preferred Delivery Date&quot;; just enter the
          date you would like your order to be delivered.
        </p>

        <h2>PO Boxes:</h2>
        <p>We do not ship to PO Boxes.</p>

        <h2>International Shipping:</h2>
        <p>
          We ship internationally; however, we cannot ship to Mexico or any
          countries in Africa. In addition, any gift baskets containing liquor,
          wine, beer, fruit & cheesecakes cannot be shipped outside the United
          States and Canada. We do not ship internationally to PO Boxes.
        </p>

        <h2>APO/FPO:</h2>
        <p>We ship to APO or FPO addresses using the US Postal Service only.</p>

        <h2>LIQUOR, WINE & BEER SHIPPING:</h2>
        <p>
          We cannot ship liquor, wine, or beer outside of the United States. All
          gift baskets containing liquor, wine, or beer require a signature of
          an adult over the age of 21 for delivery. Please keep this in mind
          when choosing where the gift basket will be delivered and calculating
          the delivery date. Our shipping carriers will make three attempts to
          deliver the gift before they return it to our supplier.
        </p>
        <div>
          <h2>XGIFTSS.COM will not be responsible for gift baskets:</h2>
          <p>
            where an adult is not available to sign. We advise that you let the
            recipient know a gift is on its way. No refund will be issued as a
            result of your gift basket containing Liquor, wine, or beer being
            returned to us due to an adult over 21 not being available to sign
            for the delivery. However, if the gift basket is returned, we will
            be happy to reship it to another location for only the cost of
            shipping and handling.
          </p>

          <h2>CHOCOLATE IN WARM WEATHER:</h2>
          <p>
            During warm weather months (typically May-September), the chocolates
            contained in gift baskets may be substituted to avoid damage due to
            melting. Items containing chocolate must be shipped Expedited
            (Overnight or 2 Day Expedited) to avoid melting. If you choose a
            method other than Overnight or 2 Day Expedited, XGIFTSS.COM will not
            be held responsible for damage when the melting occurs. If your gift
            recipient is not available at the time of delivery, we cannot be
            responsible for damage that occurs to those gifts having their
            delivery delayed or items delivered and left unattended in warm
            weather.
          </p>

          <h2>HOSPITAL DELIVERY:</h2>
          <p>
            If you are shipping to a hospital, please be aware that often gifts
            are shipped to the receiving department and then need to be taken by
            hospital staff to the patient. We are not responsible for gift
            baskets becoming lost or misplaced once it is delivered to the
            hospital, failure of the hospital staff to deliver the gift to the
            patient&apos;s room or arriving after a patient has been released.
          </p>

          <h2>RETURNS:</h2>
          <p>
            Please contact Customer Service at support@xgiftss.com if you wish
            to return your gift basket. All gift baskets should be returned
            within 14 days of delivery in their original packaging. All other
            claims (damage, not delivered on time, etc.) must be made within 45
            days.
          </p>
        </div>
      </div>
      <Carousel1 />
    </div>
  );
}
