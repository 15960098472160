import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  cartAmountIncrement,
  cartAmountDencrement,
  proccedCheckoutToggle,
} from "../../features/cart/cartSlice.js";
import getRandomProducts from "../../functions/getRandomProducts.ts";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  CardMedia,
} from "@mui/material";

interface Props {
  product: Array;
  disabled: false | boolean;
}

type ProductData = {
  productData: [];
  onEdit: () => void;
  onDelete: () => void;
};

type Product = {
  product: [];
  onEdit: () => void;
  onDelete: () => void;
  onManageDiscount: () => void;
};

export default function ProductCard({ product, disabled }: Props) {
  const { sku, imgLink, retail, item, currency } = product;
  const { cartAmount } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  if ((disabled == undefined) | false) {
    disabled = false;
  } else {
    disabled = true;
  }

  const navigate = useNavigate();

  const handleClick = () => {
    const seed = Math.floor(Math.random() * 1000); // Generate a new seed value
    const newProducts = getRandomProducts(product, 4, 1000); // Get a new set of random products
    // Navigate to the item page with the selected product
  };

  if (disabled == false) {
    return (
      <Link>
        <Link to={`/item-page/${sku}`} onClick={() => handleClick}>
          <div className="image_container">
            {product.imgLink && (
              <img src={imgLink} alt={item} className="product_image" />
            )}
          </div>
        </Link>

        <div className="content_container">
          <p className="product_name">{item}</p>
          <p className="product_price">
            {retail} {`(${currency.toUpperCase()})`}
          </p>
          <button
            className="add_to_cart_button"
            onClick={(e) => {
              e.preventDefault();
              dispatch(proccedCheckoutToggle(sku));
              dispatch(cartAmountIncrement(sku));
            }}
          >
            add to cart
          </button>
        </div>
      </Link>
    );
  } else {
    return (
      <Link>
        <div className="image_container">
          {product.imgLink && (
            <img src={imgLink} alt={item} className="product_image" />
          )}
        </div>

        <div className="content_container">
          <p className="product_name">{item}</p>
          <p className="product_price">{retail}</p>
          {/*   <button className="add_to_cart_button">add to cart</button>*/}
        </div>
      </Link>
    );
  }
}

const ShippingMethods = ({ productData }: ProductData1) => {
  const product = productData[0].shippingMethods;
  console.log(product);
  return (
    <Box
      id="shipping-methods-admin"
      sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
    >
      {Object.entries(product ? product : {}).map(([country, methods]) => (
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          key={country}
        >
          <p>
            <b>{country}</b>
          </p>
          {methods.map((method) => (
            <p key={method?.name}>
              {method?.name} - ${method?.price === 0 ? "Free" : method?.price}
            </p>
          ))}
        </Box>
      ))}
    </Box>
  );
};

const ProductAdminSkeleton = ({ productData }: ProductData) => {
  return (
    <>
      {Array.isArray(productData) &&
        productData.map((product) => (
          <Box
            key={product.sku}
            sx={{
              display: "flex",
              maxWidth: "400px",
              height: "300px",
              flexDirection: "column",
              gap: 1,
            }}
            className="product_card_skeleton"
          >
            <div className="image_skeleton">
              {product?.imgLink && (
                <>
                  <p>
                    <b>Image</b>
                  </p>
                  <img
                    src={product?.imgLink}
                    alt={product?.item}
                    className="product_image"
                  />
                </>
              )}
            </div>

            <div className="content_container">
              <>
                <p>
                  <b>name</b>
                </p>
                <p className="product_name">{product?.item}</p>
              </>
              <>
                <p>
                  <b>price</b>
                </p>
                <p className="product_price">{product?.retail}</p>
              </>
              <>
                <p>
                  <b>Shipping Methods</b>
                </p>
                <ShippingMethods productData={productData ? productData : []} />
              </>
              <>
                <p>
                  <b>desciption</b>
                </p>
                <p className="product_name">{product?.description}</p>
              </>
              <>
                <p>
                  <b>contents</b>
                </p>
                <p className="product_name">{product?.contents}</p>
              </>
              <>
                <p>
                  <b>measurments</b>
                </p>
                <p className="product_name">{product?.measurments}</p>
              </>
            </div>
          </Box>
        ))}
    </>
  );
};

const VenProductCard = ({
  product,
  onEdit,
  onDelete,
  onManageDiscount,
}: Product) => {
  const discountAppliedTimestamp =
    product.discountAppliedAt && new Date(product.discountAppliedAt).getTime();

  const currentTimestamp = Date.now();

  const durationInMinutes =
    product.durationInMinutes && !isNaN(product.durationInMinutes)
      ? product.durationInMinutes * 60 * 1000
      : 0;

  //right now display the amount of time left in the sale does not work when trying to display the amount of time left
  const remainingDays =
    discountAppliedTimestamp && durationInMinutes
      ? Math.ceil(
          (discountAppliedTimestamp + durationInMinutes - currentTimestamp) /
            (24 * 60 * 60 * 1000)
        )
      : 0;

  const displayPrice = product.discountedPrice
    ? `$${product.discountedPrice.toFixed(2)} ${
        remainingDays > 0 ? `(Sale ends in ${remainingDays} days)` : ""
      }`
    : `$${product.price?.toFixed(2)}`;

  const saleLabel =
    product.discountedPrice && remainingDays > 0
      ? `Discounted (Sale ends in ${remainingDays} days)`
      : product.discountedPrice
      ? `Discounted`
      : null;

  return (
    <Card variant="outlined">
      <CardMedia
        component="img"
        image={product.images}
        alt={product.name}
        sx={{ height: "200px" }}
      />
      <CardContent>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" component="div">
          {product.sku}
        </Typography>
        <Typography variant="body2" component="div">
          {displayPrice}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.measurements}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.weight}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.contents}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.category}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Button variant="contained" onClick={onManageDiscount}>
            management
          </Button>

          <Box sx={{ display: "flex", gap: 1 }}>
            <Button variant="contained" onClick={onEdit}>
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => onDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const VenStorefrontProductCard = ({
  product,
  onEdit,
  onDelete,
  onManageDiscount,
}: Product) => {
  const discountAppliedTimestamp =
    product?.discountAppliedAt && new Date(product?.durationInMinutes)
      ? product?.durationInMinutes * 60 * 1000
      : 0;

  //right now display the amount of time left in the sale does not work when trying to display the amount of time left
  const remainingDays =
    discountAppliedTimestamp && durationInMinutes
      ? Math.ceil(
          (discountAppliedTimestamp + durationInMinutes - currentTimestamp) /
            (24 * 60 * 60 * 1000)
        )
      : 0;

  const displayPrice = product?.discountedPrice
    ? `$${product.discountedPrice.toFixed(2)} ${
        remainingDays > 0 ? `(Sale ends in ${remainingDays} days)` : ""
      }`
    : `$${product.price?.toFixed(2)}`;

  const saleLabel =
    product.discountedPrice && remainingDays > 0
      ? `Discounted (Sale ends in ${remainingDays} days)`
      : product.discountedPrice
      ? `Discounted`
      : null;

  return (
    <Card
      variant="outlined"
      sx={{
        cursor: "pointer",
        minWidth: "275px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        src={product?.images}
        alt={product?.name}
        sx={{ height: 200 }}
      />
      <CardContent>
        <Typography variant="h5" component="div">
          {product?.name}
        </Typography>

        <Typography variant="p" component="div" sx={{ fontSize: 14 }}>
          {product?.price?.toFixed(2)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export {
  ProductCard,
  ProductAdminSkeleton,
  VenProductCard,
  VenStorefrontProductCard,
};
