import cvsData from "../data/csvData.js";
import GGBproducts from "../data/GGB-products.json";
import DSPproducts from "../data/DSP-products.json";
import fetchExchangeRate from "./fetchExchangeRate.ts";
import priceExchangeToUsd from "../functions/priceExchangeToUsd.ts";
import wineCountryProdcts from "../data/productData.json";
import axios from "axios";

const url = `${process.env.REACT_APP_EXCHANGE_RATE_URL_API}`;

//await the exchange rate function to get the daily exchange rate
const exchangeRate = await fetchExchangeRate(url);
//get the converted exchange rate from the cad to usd dollar amount
const cadToUsdDollar = parseFloat(exchangeRate.USD.toFixed(2));

const baseDeliveryPrice = priceExchangeToUsd(14.99, cadToUsdDollar);
console.log(baseDeliveryPrice);

const rows = cvsData.split("\n");
const CGBProductCard = rows.slice(3).map((row) => {
  const values = row.split(",");
  const product = {};

  if (values.length >= 10) {
    product.sku = values[0].replace(/"/g, "");
    product.item = values[1].replace(/"/g, "");
    product.retail = values[2].replace(/"/g, "", "$");
    product.wholesale = values[3].replace(/"/g, "");
    product.canadaGround = values[4].replace(/"/g, "");
    product.OnOvernightGuaranteed = values[5].replace(/"/g, "");
    product.otherOvernightGuaranteed = values[6].replace(/"/g, "");
    product.On3DayGuaranteed = values[7].replace(/"/g, "");
    product.otherProvinces3DayGuaranteed = values[8].replace(/"/g, "");
    product.canadaPost = values[9].replace(/"/g, "");
    product.usaGround = values[10].replace(/"/g, "");
    product.usaAndCanada = values[11].replace(/"/g, "");
    product.imgFoundIn = values[12].replace(/"/g, "").split("|");
    product.imgLink = values[13].replace(/"/g, "");
    product.contents = values[14].replace(/"/g, "");
    product.description = values[15].replace(/"/g, "");
    product.measurments = values[19].replace(/"/g, "");
    product.weight = values[18].replace(/"/g, "");
    product.shipsTo = ["usa", "canada"];
    product.currency = "usd";
    product.addPrice = 14.99;
    product.selectedShippingPrice = 0;
    product.vendor = "CGB";

    product.shippingMethods = {
      canada: [
        { name: "Free Shipping", price: 0 },
        {
          name: "2 - 7 Days Canada Post",
          price: Number(product.canadaPost.replace("$", "")),
        },
      ],
      usa: [
        {
          name: "US Ground",
          price: Number(product.usaGround.replace("$", "")),
        },
      ],
    };

    product.retail =
      Number(product.retail.replace("$", "")) + Number(product.addPrice);
    product.retail = priceExchangeToUsd(product.retail, cadToUsdDollar);
    product.retail = "$" + product.retail.toFixed(2);
    console.log(product.retail);
  } else {
    console.log("Invalid data format for row: ", row);
  }

  return product;
});

const wineCountyProducts = wineCountryProdcts.map((item) => {
  return {
    imgLink: item.Thumbnail,
    item: item.title,
    retail: parseFloat(item.Price).toFixed(2),
    sku: `${item?.sku}`,
    description: item?.Description,
    imgFoundIn: item?.imgFoundIn,
    measurments:
      "measurments: " + item.Height + "x" + item.Width + "x" + item.Length,
    weight: item.Weight ? "weight: " + item.Weight + "." : "",
    currency: "usd",
    shipsTo: ["usa"],
    selectedShippingPrice: 0,
    vendor: "CGB",
    shippingMethods: {
      usa: [
        {
          name: "Free Shipping",
          price: "Free",
        },
      ],
    },
  };
});

/* const GGBProductCard = GGBproducts.map((item) => {
  if (item.OfferName) {
    // Convert CurrentPrice and GroundShippingPrice to numbers
    const currentPrice = parseFloat(item?.CurrentPrice) || 0;
    const groundShippingPrice =
      parseFloat(item?.shippingMethods?.usa[0]?.price) || 0;

    // Calculate the new retail price by adding GroundShippingPrice to the base retail price
    const newRetailPrice = currentPrice + groundShippingPrice;

    return {
      imgLink: item.ReferenceImageURL,
      item: item.OfferName,
      retail: `$${newRetailPrice.toFixed(2)}`, // Update the retail price with the new value
      sku: `${item.Model}`,
      description: item.Description,
      imgFoundIn: item.category.split("|"),
      measurments: "measurments: " + item.boxDimensions,
      weight: item.boxWeight ? item.boxWeight : "",
      shipsTo: ["usa"],
      addPrice: groundShippingPrice, // You might want to keep this if needed
      selectedShippingPrice: 0,
      contents: item.Contents,
      vendor: "GGB",
      currency: "usd",
      shippingMethods: {
        usa: [
          {
            name: "Free Shipping",
            price: "Free",
          },

          {
            name: "2-Day Rate",
            price: item["2-Day Rate"].toFixed(2) - item.GroundShippingPrice,
          },
          {
            name: "3-Day Rate",
            price: item["3-Day Rate"].toFixed(2) - item.GroundShippingPrice,
          },
        ],
      },
    };
  } else {
    return item;
  }
});
const DSPProductCard = DSPproducts.map((item) => {
  if (item.productName) {
    return {
      imgLink: item.productImages,
      item: item.productName,
      retail: `$${item.price.toFixed(2)}`,
      sku: `${item.SKU}`,
      description: item.Description,
      imgFoundIn: item.category.split("|"),
      measurments:
        "measurments: " + item.Height + "x" + item.Width + "x" + item.Depth,
      weight: item.Weight ? "weight: " + item.Weight + "." : "",
      currency: "usd",
      shipsTo: ["usa"],
      selectedShippingPrice: 0,
      vendor: "GAGB",
      shippingMethods: {
        usa: [
          {
            name: "Free Shipping",
            price: "Free",
          },
        ],
      },
    };
  } else {
    return item;
  }
}); */

//api request to fetch the ven product data from the backend
const baseApi = axios.create({
  baseURL: "https://xgiftssapi.onrender.com",
  headers: {
    "Content-Type": "application/json",
  },
});

const getVenProductData = async () => {
  try {
    const response = await baseApi.get("/get-ven-products");
    console.log(response.data);
    return response;
  } catch (error) {
    console.log("Failed to fetch ven products", error);
  }
};

const product = [
  ...CGBProductCard,
  ...wineCountyProducts /* , ...GGBProductCard, ...DSPProductCard */,
];

//to see all uniqe categories in the console
const categories = product.reduce((acc, product) => {
  // Ensure product.imgFoundIn is an array before calling forEach
  const productCategories = Array.isArray(product.imgFoundIn)
    ? product.imgFoundIn
    : [];
  productCategories.forEach((category) => {
    acc.add(category);
  });
  return acc;
}, new Set());

console.table(categories);

export { product, getVenProductData };
