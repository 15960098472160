import React, { useState } from "react";
import { Box, Select, MenuItem, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setPrice } from "../../features/productFilter/filterSlice.js";

interface Styles {
  styles: Record<string, string>;
}

const PriceFilter = ({ styles }: Styles) => {
  const { category } = useParams();
  const selectedCategory = category;
  const dispatch = useDispatch();
  const price = useSelector((state) => state.filter.selectedPrice);

  const handlePriceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelectedPrice = event.target.value as number;
    dispatch(setPrice(newSelectedPrice));
  };

  return (
    <Box sx={{ maxWidth: "auto" }}>
      <InputLabel id="price-filter-label">Price Filter</InputLabel>
      <Select
        variant="standard"
        labelId="price-filter-label"
        id="price-filter-label"
        value={price}
        onChange={handlePriceChange}
        className={styles.filter_button}
        label="Price Filter"
        sx={{ width: "100px" }}
      >
        <MenuItem value="" disabled selected>
          select price
        </MenuItem>
        <MenuItem value={0}>no filter</MenuItem>
        <MenuItem value={50}>$50</MenuItem>
        <MenuItem value={100}>$100</MenuItem>
        <MenuItem value={200}>$200</MenuItem>
        {/* Add more price options as needed */}
      </Select>
    </Box>
  );
};

export { PriceFilter };
