import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  SwipeableDrawer,
  MenuItem,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { setFilterProducts } from "../../features/productFilter/filterSlice.js";
import { useNavigate } from "react-router-dom";
import ProductSearchbar from "../informationComponents/ProductSearchbar.tsx";
import CountrySelect from "../informationComponents/CountrySelect.tsx";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as XIcon } from "../../images/xgiftss-x-icon.svg";

const AppBar = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    mainDrawer: false,
    subDrawer: false,
  });

  const [selectedCategory, setSelectedCategory] = useState(null);

  const toggleDrawer = (drawer, open) => () => {
    setState({ ...state, [drawer]: open });
  };

  const closeDrawers = () => {
    setState({ mainDrawer: false, subDrawer: false });
  };

  const categoryData = [
    {
      mainCategory: "Occasions & Holidays",
      subCategories: [
        "Birthday",
        "Get Well",
        "Kosher",
        "Healthy",
        "Baby",
        "Father's Day",
        "Mother's Day",
        "Christmas",
        "Thanksgiving&Fall",
        "Hanukkah",
        "Housewarming",
        "New Year's",
        "Thank You",
        `Anniversary&Wedding`,
        "Snacks",
        "Halloween",
        "Congratulations",
        "Occasions",
        "Easter",
        "Summer",
        "Graduation",
        "Back to School",
      ],
    },
    {
      mainCategory: "Gourmet Gift Baskets",
      subCategories: [
        "Chocolate",
        "Gourmet",
        "Fresh Fruit",
        "Cheese & Meat",
        "Bakery",
        "Canadian",
      ],
    },
    {
      mainCategory: "Recipient",
      subCategories: [
        "Kids",
        "For Her",
        "For Him",
        "Pet Lover",
        "Father's Day",
        "Mother's Day",
      ],
    },
    {
      mainCategory: "Alcohol & Other Drinks",
      subCategories: [
        "Alcohol",
        "Wine",
        "Red Wine",
        "White Wine",
        "Premium Fine Wines",
        "Rosé Wine",
        "Champagne",
      ],
    },
    {
      mainCategory: "Special Packages & Baskets",
      subCategories: [
        "Candy",
        "Gift Packages",
        "Spa",
        "Snack Baskets",
        "Meat & Cheese",
        "Fruit Baskets",
        "Comfort Food",
        "Care Package",
        "Tea Gift Baskets",
        "Baked Goods",
        "Celebration",
        "Thank You Gift Baskets",
        "Gourmet Gift Towers",
        "New Gift Baskets",
        "Sympathy Gift Baskets",
        "Cheesecake Gifts",
        "Bakery Gift Baskets",
        "Halloween Gift Baskets",
        "Towers",
      ],
    },
  ];

  const selectedSubCategories =
    selectedCategory !== null
      ? categoryData.find(
          (category) => category.mainCategory === selectedCategory
        )?.subCategories
      : [];

  const setCategory = (event, categoryName) => {
    event.preventDefault();

    // Redirect to the Corporate Order page and close all drawers
    navigate("/corporate-order/corporate");
    closeDrawers();
  };

  useEffect(() => {
    // Close drawers after navigation
    closeDrawers();
  }, [navigate]);

  const list = (
    <>
      <Box
        sx={{ width: "auto" }}
        role="presentation"
        onClick={() => {
          toggleDrawer("mainDrawer", false)();
          toggleDrawer("subDrawer", true)();
        }}
        onKeyDown={toggleDrawer("subDrawer", true)}
      >
        <List>
          {categoryData.map((category) => (
            <ListItem
              key={category.mainCategory}
              disablePadding
              onClick={() => {
                setSelectedCategory(category.mainCategory);
                toggleDrawer("mainDrawer", false);
                toggleDrawer("subDrawer", true);
              }}
            >
              <ListItemButton>
                <ListItemText primary={category.mainCategory} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem
            disablePadding
            onClick={(e) => {
              setCategory(e);
              toggleDrawer("mainDrawer", false);
              closeDrawers();
            }}
          >
            <ListItemButton>
              <ListItemText primary={"Corporate Order"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  const subCategoryList = (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={() => {
        toggleDrawer("subDrawer", false)();
        closeDrawers();
      }}
      onKeyDown={() => {
        toggleDrawer("subDrawer", false)();
        closeDrawers();
      }}
    >
      <List>
        {selectedSubCategories.map((text) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={`/product-page/${text}`}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const mobileActive = useMediaQuery("(max-width: 728px)");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* Main category drawer */}
      <SwipeableDrawer
        anchor="right"
        open={state.mainDrawer}
        onClose={toggleDrawer("mainDrawer", false)}
        onOpen={toggleDrawer("mainDrawer", true)}
      >
        {list}

        <Box sx={{ padding: "16px" }}>
          <CountrySelect />
        </Box>
        <Box sx={{ backgroundColor: "#fcfaf7", padding: "16px" }}>
          <ProductSearchbar />
        </Box>
      </SwipeableDrawer>

      {/* Sub-category drawer */}
      <SwipeableDrawer
        anchor="right"
        open={state.subDrawer}
        onClose={toggleDrawer("subDrawer", false)}
        onOpen={toggleDrawer("subDrawer", true)}
      >
        {subCategoryList}
      </SwipeableDrawer>

      {/* Bottom navigation */}
      <Box sx={{ position: "sticky", bottom: "0", zIndex: 10 }}>
        <BottomNavigation showLabels>
          <BottomNavigationAction
            label="Menu"
            icon={<MenuIcon />}
            onClick={handleClick}
          />

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Link to="/dev">Home</Link>
            </MenuItem>

            <MenuItem onClick={handleClose}>
              <Link to="/privacy-policies">Privacy Policies</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/about-us">About us</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/Shipping-policies">Shipping Policies</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/return-policies">Returns & Shipping</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to="/frequent-questions">Q&A</Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a id="mail-link" href="mailto:info@xgiftss.com">
                info@xgiftss.com
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a id="mail-link" href="mailto:info@xgiftss.com">
                support@xgiftss.com
              </a>
            </MenuItem>
          </Menu>
          <BottomNavigationAction
            label="Categories"
            icon={<XIcon />}
            onClick={toggleDrawer("mainDrawer", true)}
          />
          <BottomNavigationAction
            label="Calendar"
            icon={<CalendarMonthIcon />}
            component={Link}
            to={"/calendar"}
          />
        </BottomNavigation>
      </Box>
    </>
  );
};

export default AppBar;
