import React from "react";
import styles from "../../styles/partials/navigation/footer.module.css";
import { ReactComponent as MailIcon } from "../../images/Communication/Mail.svg";
import EmailInput from "../../components/inputs/EmailInput.tsx";
import NavSection from "./footerPartials/NavSection.tsx";
import AncorTag from "../buttonsComponents/AncorTag.tsx";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";

export default function Footer() {
  const navList1 = [
    "track order",
    "shipping address",
    "corparate gifts",
    "e-mail unsubscribe",
    "receive our catalogs",
    "submit feedbak",
  ];
  const navList2 = ["independent gift makers", "become our partner"];
  const navList3 = ["Facebook", "Instagram", "Linkedin", "Twitter"];

  return (
    <>
      <div className={styles.footer_container}>
        <div className={styles.contact_bar}>
          <div className={styles.contact_btn_container}>
            <AncorTag
              to="mailto:support@xgiftss.com"
              text="support@xgiftss.com"
              styles={styles}
            />
            <MailIcon />
          </div>

          <div className={styles.newsletter_footer_signup}>
            <p>Subscribe for coupons</p>
            <EmailInput
              styles={styles}
              placeholder="email"
              button="SUBSCRIBE"
            />
          </div>
        </div>

        <Box
          className={styles.footer_navigation}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Link to="/partner-login">
            <Button
              sx={{
                backgroundColor: "#fcfaf7",
                color: "#07130c",
                ":hover": {
                  backgroundColour: "#07130c",
                  color: "#fcfaf7",
                },
              }}
            >
              xgiftss seller
            </Button>
          </Link>
        </Box>
        <div className={styles.footer_navigation}>
          <div className={styles.footer_nav_section}>
            <h5 className={styles.section_header}></h5>
            <ul className={styles.section_list}>
              <li>
                <Link to="privacy-policies">Privacy Policies</Link> |{" "}
                <Link to="/terms-and-conditions">Terms & Condintions</Link> |{" "}
                <Link to="/about-us">About us</Link>
              </li>
              <li>| </li>
            </ul>
          </div>
        </div>
        <p>&copy; 2003, xgiftss.com, apponex Inc.</p>
      </div>
    </>
  );
}
