import React, { useState, useEffect } from "react";
import { VenStorefrontProductCard } from "../components/informationComponents/ProductCard.tsx";
import { getVenProductData } from "../data/products.js";
import { Container, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function VenProductDisplay() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const venProductData = async () => {
      try {
        const response = await getVenProductData();
        setProducts(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    venProductData();
  }, []);

  useEffect(() => {
    console.log(products); // Log the updated state when it changes
  }, [products]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        minHeight: "80vh",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100vw",
      }}
    >
      <Typography variant="h3"> VenProductDisplay </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",

          padding: "2em 0",
        }}
      >
        {products.map((product) => (
          <Link key={product._id} to={`/item-page/${product._id}`}>
            <VenStorefrontProductCard product={product} />
          </Link>
        ))}
      </Box>
    </Container>
  );
}
